import { Style, Fill, RegularShape, Stroke } from 'ol/style';
import { SourceIndexes } from '../config/SourceConfig';

export const featureStyle = f => {
  const type = f.get('type');

  switch (type) {
    case SourceIndexes.PORT:
      return new Style({
        image: new RegularShape({
          fill: new Fill({
            color: 'rgb(0,0,255)'
          }),
          stroke: new Stroke({
            color: 'rgb(0,0,255)'
          }),
          radius: 14,
          points: 4,
          angle: Math.PI / 4
        }),
        scale: 3.5,
        zIndex: 2
      });

    case SourceIndexes.PORT_FACILITY:
      return new Style({
        image: new RegularShape({
          fill: new Fill({
            color: 'rgb(0,0,0)'
          }),
          stroke: new Stroke({
            color: 'rgb(0,0,0)'
          }),
          radius: 12,
          points: 4,
          angle: Math.PI / 4
        }),
        scale: 3.5,
        zIndex: 2
      });

    case SourceIndexes.QUAY:
      return new Style({
        image: new RegularShape({
          fill: new Fill({
            color: 'rgb(0,255,0)'
          }),
          stroke: new Stroke({
            color: 'rgb(0,255,0)'
          }),
          radius: 10,
          points: 4,
          angle: Math.PI / 4
        }),
        scale: 3.5,
        zIndex: 2
      });
    case SourceIndexes.ROUTE:
      return new Style({
        fill: new Fill({
          color: 'rgb(0,0,0)' //'rgba(255, 0, 0, 0.7)'
        }),
        stroke: new Stroke({
          color: 'rgb(0,0,0)', //'rgba(255, 0, 0, 1)',
          width: 4
        }),
        zIndex: 2
      });
    case SourceIndexes.MTR:
    case SourceIndexes.CPR:
    case SourceIndexes.LOCATION:
    case SourceIndexes.VTS:
    case SourceIndexes.SVALBARD:
      return new Style({
        fill: new Fill({
          color: 'rgba(255,255,255,0.7)'
        }),
        stroke: new Stroke({
          color: 'rgba(255,255,255,1)',
          width: 3
        }),
        zIndex: 2
      });

    default:
      return null;
  }
};
