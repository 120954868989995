import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import '../views/PageView.scss';

const PrivateRoute = ({ children, redirectPath }) => {
  const user = useContext(UserContext);

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default PrivateRoute;
