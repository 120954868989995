import React from 'react';
import './Legend.scss';

export default () => (
  <div className="routeInfo-legend">
    <p className="legend-routes">Reference routes</p>
    <p className="legend-ports">Ports</p>
    <p className="legend-portFacilities">Port facilities</p>
    <p className="legend-quays">Quays</p>
  </div>
);
