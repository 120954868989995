import React, { createContext, useEffect, useState, useCallback } from 'react';
import request from 'superagent';
import useSessionId from '../hooks/useSessionId';
import pubsub from '../utils/pubsub';

import config from '../config/kystverket';

export const UserContext = createContext();

function useUser() {
  const [user, setUser] = useState(undefined);
  const [sessionId] = useSessionId();

  const loadUser = useCallback(() => {
    request
      .get(config.adaptiveUrl + 'api/Profile/me')
      .set('Authorization', 'Bearer ' + sessionId)
      .then(res => res.body)
      .then(user => {
        setUser(user);
      })
      .catch(err => {
        console.error(err);
        setUser(null);
      });
  }, [sessionId]);

  useEffect(() => {
    return pubsub.subscribe('profile/saved', loadUser);
  }, [loadUser]);

  useEffect(() => {
    if (!sessionId) {
      setUser(null);
      return;
    }

    setUser(undefined);
    loadUser();
  }, [sessionId, loadUser]);

  return user;
}
export const UserContextProvider = ({ children }) => {
  const user = useUser();

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
