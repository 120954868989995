import React, { useCallback, useMemo } from 'react';
import './BasemapToggle.scss';

const BasemapToggle = ({ mode, setMode }) => {
  const onBasemapToggle = useCallback(() => {
    setMode(mode === 'coast' ? 'enc' : mode === 'enc' ? 'gray' : 'coast');
  }, [mode, setMode]);

  const title = useMemo(() => {
    switch (mode) {
      case 'enc':
        return 'Bytt til gråtonekart';
      case 'coast':
        return 'Bytt til ENC sjøkart';
      default:
        return 'Bytt til sjøkart';
    }
  }, [mode]);

  return (
    <button
      className={'btn-map basemap-toggle ' + mode}
      onClick={onBasemapToggle}
      title={title}
    ></button>
  );
};

export default BasemapToggle;
