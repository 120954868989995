import React, { useState } from 'react';
import Icon from '../Icon';
import { WmsLayer } from '@avinet/react-openlayers';
import config from '../../config/kystverket';
import './LayerSwitch.scss';

const LayerSwitch = () => {
  const [open, setOpen] = useState(false);
  const [mtrActive, setMtrActive] = useState(true);
  const [cprActive, setCprActive] = useState(true);
  const [pilotActive, setPilotActive] = useState(true);
  const [svalbardActive, setSvalbardActive] = useState(true);

  return (
    <div className={['layer-switch', open ? 'open' : ''].join(' ')}>
      <div className="layer-switch--icons" onClick={() => setOpen(!open)}>
        <Icon name="layerSwitch" />
        <Icon name={open ? 'chevronup' : 'chevrondown'} />
      </div>
      <div className="layer-switch--layers">
        <div className="layer-switch--layer">
          <input
            type="checkbox"
            id="vts"
            onChange={() => setMtrActive(!mtrActive)}
            checked={mtrActive}
          />
          <label htmlFor="vts">Local Regulations (VTS)</label>
        </div>
        <div className="layer-switch--layer">
          <input
            type="checkbox"
            id="pec"
            onChange={() => setCprActive(!cprActive)}
            checked={cprActive}
          />
          <label htmlFor="pec">Local Regulations for Use of PEC</label>
        </div>
        <div className="layer-switch--layer">
          <input
            type="checkbox"
            id="pilot"
            onChange={() => setPilotActive(!pilotActive)}
            checked={pilotActive}
          />
          <label htmlFor="pilot">Pilot Boarding</label>
        </div>
        <div className="layer-switch--layer">
          <input
            type="checkbox"
            id="svalbard"
            onChange={() => setSvalbardActive(!svalbardActive)}
            checked={svalbardActive}
          />
          <label htmlFor="svalbard">Svalbard</label>
        </div>
      </div>
      {mtrActive && (
        <WmsLayer
          id="MTR"
          name="Local Regulations (VTS)"
          uri={config.a3WmsUrl}
          layerName={config.layerIds.mtr}
          version="1.1.1"
          singleTile
          key="mtr"
        />
      )}
      {cprActive && (
        <WmsLayer
          id="CPR"
          name="Local Regulations for Use of PEC"
          uri={config.a3WmsUrl}
          layerName={config.layerIds.cpr}
          version="1.1.1"
          singleTile
          key="cpr"
        />
      )}
      {pilotActive && (
        <WmsLayer
          id="Pilot"
          name="Pilot Boarding"
          uri={config.a3WmsUrl}
          layerName={config.layerIds.pilot}
          version="1.1.1"
          singleTile
          key="pilot"
        />
      )}
      {svalbardActive && (
        <WmsLayer
          id="Svalbard"
          name="Svalbard"
          uri={config.a3WmsUrl}
          layerName={config.layerIds.svalbard}
          version="1.1.1"
          singleTile
          key="svalbard"
        />
      )}
    </div>
  );
};

export default LayerSwitch;
