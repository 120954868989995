import request from 'superagent';
import config from '../config/kystverket';

/**
 * Register consent.
 *
 * @param {string} sessionId
 * @param {{ category: string, version: number }} consent
 */
export function registerConsent(sessionId, consent) {
  request
    .post(config.adaptiveUrl + 'WebServices/generic/Privacy.asmx/Accept')
    .set('gm_session_id', sessionId)
    .send({ category: consent, version: 1 })
    .then(res => {
      if (res.body.d.success) return;
      console.error('Consent save failed', res.body.d);
    })
    .catch(err => console.error('Consent save failed', err));
}
