import React, { useState, useContext, useEffect } from 'react';
import Title from '../components/Title';
import LoadingMask from '../components/loading/LoadingMask';
import Icon from '../components/Icon';
import ProfileEditor from '../components/ProfileEditor';
import { UserContext } from '../contexts/UserContext';

import './LoginView.scss';

const ProfileView = () => {
  const [successMessage, setSuccessMessage] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const user = useContext(UserContext);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }
  }, [showSuccess]);

  return (
    <div className="userForm-container">
      <div className="userForm-content" id="formView-scrollPane">
        {!user && <LoadingMask />}
        <Title title="Profile - Kystverket" />

        <div className="profile-title--container">
          <h2>My profile</h2>

          <span className={'success' + (showSuccess ? '' : ' hide')}>
            <Icon name="check" /> {successMessage}
          </span>
        </div>

        <ProfileEditor
          user={user}
          onSaved={() => {
            setSuccessMessage('Changes are saved!');
            setShowSuccess(true);
          }}
        />
      </div>
    </div>
  );
};

export default ProfileView;
