import React, { useEffect, useRef } from 'react';
import useCmsPage from '../hooks/useCmsPage';
import Title from '../components/Title';
import Loading from '../components/loading/Loading';
import { fixLinks } from '../utils/path';
import './PageView.scss';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

function getPath(params) {
  return params.page || 'index';
}

const PageView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const didMountRef = useRef();
  const page = useCmsPage(getPath(params));

  useEffect(() => {
    if (!didMountRef.current) {
      // do componentDidMount logic
      didMountRef.current = true;
    } else {
      // do componentDidUpdate logic
      fixLinks(navigate);
    }
  });

  function render404() {
    return (
      <div>
        <h3>Page not found</h3>
        <p>
          Please check the address or return to the <a href="/">Home page</a>
        </p>
      </div>
    );
  }

  return (
    <div className="page-view--container">
      <Title title={(page && page.title) + ' - Kystverket'} />
      <div className="page-view--content" id="pageView-scrollPane">
        {!page ? (
          <Loading />
        ) : page === false ? (
          render404()
        ) : (
          <div className="route-info--cms" dangerouslySetInnerHTML={{ __html: page.content }} />
        )}
      </div>
    </div>
  );
};

PageView.propTypes = {
  nav: PropTypes.array,
  page: PropTypes.object,
  pageNav: PropTypes.array,
  pageLoading: PropTypes.bool,
  loadNav: PropTypes.func,
  loadPage: PropTypes.func,
  match: PropTypes.object
};

export default PageView;
