import React, { useCallback, useMemo } from 'react';
import Title from '../components/Title';
import LocationsAfter from '../components/article/LocationsAfter';
import RouteAfter from '../components/article/RouteAfter';
import ZoomTo from '../components/map/ZoomTo';
import useItem from '../hooks/useItem';
import { useParams, useNavigate } from 'react-router-dom';
import { SourceIndexes, titlePrefix } from '../config/SourceConfig';
import { buildRouteName } from '../utils/utils';
import { VectorSource } from '@avinet/react-openlayers';
import { featureStyle } from '../utils/featureStyle';

import config from '../config/kystverket';

import './ArticleDetailView.scss';

function getMediaUrl(uuid, title = '') {
  return (
    config.adaptiveUrl +
    'WebServices/generic/Media.asmx/Download?uuid=' +
    uuid +
    '&filename=' +
    title
  );
}

const Port = ({
  councilname,
  locationcode,
  locationnamenor,
  locationnameeng,
  type,
  locationid
}) => {
  return (
    <div className="article">
      <p className="articletype port">{titlePrefix(type)}</p>
      <h3>{locationnameeng}</h3>
      <div style={{ marginBottom: -10 }}>
        <div className="additional">
          <h5>Norwegian</h5>
          <p>{locationnamenor}</p>
        </div>
        <div className="additional">
          <h5>UN/LOCODE</h5>
          <p>{locationcode}</p>
        </div>
        <div className="additional">
          <h5>Municipality</h5>
          <p>{councilname}</p>
        </div>
      </div>
      <LocationsAfter locationid={locationid} type={type} />
    </div>
  );
};

const PortFacility = props => {
  const { councilname, portfacilityno, locationnamenor, locationnameeng, type, locationid } = props;
  return (
    <div className="article">
      <p className="articletype portfacility">{titlePrefix(type)}</p>
      <h3>{locationnameeng}</h3>
      <div style={{ marginBottom: -10 }}>
        <div className="additional">
          <h5>Norwegian</h5>
          <p>{locationnamenor}</p>
        </div>
        <div className="additional">
          <h5>Port Facility No</h5>
          <p>{portfacilityno}</p>
        </div>
        <div className="additional">
          <h5>Municipality</h5>
          <p>{councilname}</p>
        </div>
      </div>
      <LocationsAfter locationid={locationid} type={type} />
    </div>
  );
};

const Quay = ({
  councilname,
  locationcode,
  locationnamenor,
  locationnameeng,
  type,
  locationid
}) => {
  return (
    <div className="article">
      <p className="articletype quay">{titlePrefix(type)}</p>
      <h3>{locationnameeng}</h3>
      <div style={{ marginBottom: -10 }}>
        <div className="additional">
          <h5>Norwegian</h5>
          <p>{locationnamenor}</p>
        </div>
        <div className="additional">
          <h5>UN/LOCODE</h5>
          <p>{locationcode}</p>
        </div>
        <div className="additional">
          <h5>Municipality</h5>
          <p>{councilname}</p>
        </div>
      </div>
      <LocationsAfter locationid={locationid} type={type} />
    </div>
  );
};

const MTR = ({ id, section, region, factsheet, type }) => {
  return (
    <div className="article">
      <p className="articletype mtr">{titlePrefix(type)}</p>
      <h3>{section}</h3>
      <div className="additional">
        <h5>Section</h5>
        <p>{section}</p>
      </div>
      <div className="additional">
        <h5>Region</h5>
        <p>{region}</p>
      </div>
      <div>
        <a
          href={getMediaUrl(factsheet, 'mtr_' + section + '.pdf')}
          target="_blank"
          className="btn btn-info"
          rel="noopener noreferrer"
        >
          Download Factsheet
        </a>
      </div>
    </div>
  );
};

const CPR = ({
  id,
  name,
  maritimedivisionname,
  c23_day_maxlength,
  c23_night_maxlength,
  c23_day_maxdraught,
  c23_night_maxdraught,
  c1_day_maxlength,
  c1_night_maxlength,
  c1_day_maxdraught,
  c1_night_maxdraught,
  remarkenglish,
  sok_kl1,
  c1_dp,
  type
}) => {
  if (c1_dp === 0) {
    return (
      <div className="article">
        <p className="articletype cpr red">{titlePrefix(type)}</p>
        <h3>{name}</h3>
        <h4>Fairway with no PEC navigation</h4>
        <div className="additional">
          <h5>Maritime division</h5>
          <p>{maritimedivisionname}</p>
        </div>
        <div className="additional">
          <h5>No PEC navigation</h5>
        </div>
        {remarkenglish && (
          <div className="additional">
            <h5>Remark</h5>
            <p>{remarkenglish}</p>
          </div>
        )}
      </div>
    );
  } else if (c1_dp === 1 && sok_kl1 !== 0) {
    return (
      <div className="article">
        <p className="articletype cpr blue">{titlePrefix(type)}</p>
        <h3>{name}</h3>
        <h4>PEC class 1 fairway</h4>
        <div className="additional">
          <h5>Maritime division</h5>
          <p>{maritimedivisionname}</p>
        </div>

        {c23_day_maxlength > 0 && (
          <div className="additional">
            <h5>PEC class 2 / 3, day max LOA</h5>
            <p>{c23_day_maxlength}</p>
          </div>
        )}
        {c23_night_maxlength > 0 && (
          <div className="additional">
            <h5>PEC class 2 / 3, night max LOA</h5>
            <p>{c23_night_maxlength}</p>
          </div>
        )}
        {c23_day_maxdraught > 0 && (
          <div className="additional">
            <h5>PEC class 2 / 3, day max draught</h5>
            <p>{c23_day_maxdraught}</p>
          </div>
        )}
        {c23_night_maxdraught > 0 && (
          <div className="additional">
            <h5>PEC class 2 / 3, night max draught</h5>
            <p>{c23_night_maxdraught}</p>
          </div>
        )}
        {c1_day_maxlength > 0 && (
          <div className="additional">
            <h5>PEC class 1, day max LOA</h5>
            <p>{c1_day_maxlength}</p>
          </div>
        )}
        {c1_night_maxlength > 0 && (
          <div className="additional">
            <h5>PEC class 1, night max LOA</h5>
            <p>{c1_night_maxlength}</p>
          </div>
        )}
        {c1_day_maxdraught > 0 && (
          <div className="additional">
            <h5>PEC class 1, day max draught</h5>
            <p>{c1_day_maxdraught}</p>
          </div>
        )}
        {c1_night_maxdraught > 0 && (
          <div className="additional">
            <h5>PEC class 1, night max draught</h5>
            <p>{c1_night_maxdraught}</p>
          </div>
        )}
        {remarkenglish && (
          <div className="additional">
            <h5>Remark</h5>
            <p>{remarkenglish}</p>
          </div>
        )}
      </div>
    );
  } else if (c1_dp !== 0 && sok_kl1 === 0) {
    return (
      <div className="article">
        <p className="articletype cpr">{titlePrefix(type)}</p>
        <h3>{name}</h3>
        <div className="additional">
          <h5>Maritime division</h5>
          <p>{maritimedivisionname}</p>
        </div>
        <h4>PEC class 2 fairway</h4>
        {c23_day_maxlength > 0 && (
          <div className="additional">
            <h5>PEC class 2 / 3, day max LOA</h5>
            <p>{c23_day_maxlength}</p>
          </div>
        )}
        {c23_night_maxlength > 0 && (
          <div className="additional">
            <h5>PEC class 2 / 3, night max LOA</h5>
            <p>{c23_night_maxlength}</p>
          </div>
        )}
        {c23_day_maxdraught > 0 && (
          <div className="additional">
            <h5>PEC class 2 / 3, day max draught</h5>
            <p>{c23_day_maxdraught}</p>
          </div>
        )}
        {c23_night_maxdraught > 0 && (
          <div className="additional">
            <h5>PEC class 2 / 3, night max draught</h5>
            <p>{c23_night_maxdraught}</p>
          </div>
        )}
        {remarkenglish && (
          <div className="additional">
            <h5>Remark</h5>
            <p>{remarkenglish}</p>
          </div>
        )}
      </div>
    );
  }
};

const Route = ({
  auto_length,
  ssn_port_facilities,
  ssn_ports,
  ssn_quays,
  id,
  routeinfo_routename,
  extension_routenumber,
  routequalitystatus,
  routeinfo_validityperiodstart,
  type,
  olFeature
}) => {
  const routeName = useMemo(
    () =>
      buildRouteName({
        routeQualityStatus: routequalitystatus,
        routeName: routeinfo_routename,
        vps: routeinfo_validityperiodstart
      }),
    [routequalitystatus, routeinfo_routename, routeinfo_validityperiodstart]
  );

  return (
    <div className="article">
      <p className="articletype route">{titlePrefix(type)}</p>
      <h3>{routeinfo_routename}</h3>
      <RouteAfter
        id={id}
        olFeature={olFeature}
        routeName={routeName}
        routeNumber={extension_routenumber}
        autoLength={auto_length}
        portIds={ssn_ports}
        portfacilityIds={ssn_port_facilities}
        quayIds={ssn_quays}
      />
    </div>
  );
};

const Svalbard = ({ id, name, description, factsheet, type }) => {
  return (
    <div className="article">
      <p className="articletype svalbard">{titlePrefix(type)}</p>
      <h3>{name}</h3>
      <div>
        <a
          href={getMediaUrl(factsheet, 'svalbard_' + name + '.pdf')}
          target="_blank"
          className="btn btn-info"
          rel="noopener noreferrer"
        >
          Download Factsheet
        </a>
      </div>
    </div>
  );
};

const ArticleDetailView = () => {
  const { sourceId, articleId } = useParams();
  const { item } = useItem({ type: sourceId, id: parseInt(articleId) });

  const navigate = useNavigate();

  const renderArticle = useCallback(() => {
    switch (item.type) {
      case SourceIndexes.PORT:
        return <Port {...item} />;
      case SourceIndexes.PORT_FACILITY:
        return <PortFacility {...item} />;
      case SourceIndexes.QUAY:
        return <Quay {...item} />;
      case SourceIndexes.MTR:
        return <MTR {...item} />;
      case SourceIndexes.CPR:
        return <CPR {...item} />;
      case SourceIndexes.ROUTE:
        return <Route {...item} />;
      case SourceIndexes.SVALBARD:
        return <Svalbard {...item} />;
      default:
        break;
    }
  }, [item]);

  return (
    <div className="article-detail-view--container">
      {item && (
        <>
          <ZoomTo features={[item.olFeature]} />
          <VectorSource
            features={[item.olFeature]}
            layerName="detail_view_layer"
            selectable={false}
            style={featureStyle}
            zIndex={4}
          />
        </>
      )}
      <Title title="Route Info - Kystverket" />
      <button className="closeBtn" onClick={() => navigate('/')} title={'Close'}>
        <span className="glyphicon glyphicon-remove" />
      </button>
      {item && renderArticle()}
    </div>
  );
};

export default ArticleDetailView;
