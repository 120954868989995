import React, { useState, useCallback, useContext } from 'react';
import LoadingMask from '../components/loading/LoadingMask';
import Title from '../components/Title';
import request from 'superagent';
import { useForm } from 'react-hook-form';
import { setSessionId } from '../utils/sesionId';
import { UserContext } from '../contexts/UserContext';
import { useLocation } from 'react-router';
import { redirect, Link, Navigate } from 'react-router-dom';

import config from '../config/kystverket';

import './LoginView.scss';

export default function LoginView() {
  const { state: locationState } = useLocation();
  const { from } = locationState || {};
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState();
  const [loading, setLoading] = useState(false);
  const [forgottenState, setForgottenState] = useState(0);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors
  } = useForm({
    mode: 'onChange'
  });

  const onLogin = useCallback(
    (email, password) => {
      setLoading(true);
      const req = request
        .post(config.adaptiveUrl + 'api/Auth/Authenticate') // <== use for prod
        // .post('http://localhost/a_a3/api/Auth/Authenticate') // <== use for dev
        .send({
          email: email,
          password: password
        });
      req
        .then(res => res.body)
        .then(res => {
          if (!res.success) {
            setLoading(false);
            setError('general', {
              message:
                'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
            });
          } else {
            const sessionId = res.session_id;
            setSessionId(sessionId);
            setShouldRedirect(true);
          }
        })
        .catch(err => {
          if (err.response && err.response.statusCode) {
            switch (err.response.statusCode) {
              case 401:
                setError('general', {
                  message: 'The email address or password you entered was incorrect.'
                });
                break;

              default:
                setError('general', {
                  message:
                    'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
                });
                break;
            }
          } else {
            setError('general', {
              message:
                'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
            });
            console.warn(err);
          }
          setLoading(false);
        });
    },
    [setError]
  );

  const onVerifyBegin = useCallback(
    email => {
      setLoading(true);

      request
        .post(config.adaptiveUrl + 'api/Auth/VerifyBegin') // <== use for prod
        // .post('http://localhost/a_a3/api/Auth/VerifyBegin') // <== use for dev
        .set('X-Adaptive-variant', 'routeinfo')
        .send({
          email
        })
        .then(res => res.body)
        .then(res => {
          if (!res.success) {
            setLoading(false);
            setError('general', {
              message:
                'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
            });
          } else {
            setForgottenState(2);
            setLoading(false);
          }
        })
        .catch(err => {
          setError('general', {
            message:
              'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
          });
          console.warn(err);
          setLoading(false);
        });
    },
    [setError]
  );

  const onVerify = useCallback(
    (email, code) => {
      setLoading(true);
      setLoading(false);

      request
        .post(config.adaptiveUrl + 'api/Auth/Verify') // <== use for prod
        // .post('http://localhost/a_a3/api/Auth/Verify') // <== u
        .send({
          email,
          code
        })
        .then(res => res.body)
        .then(res => {
          if (!res.success) {
            setLoading(false);
            setError('invalid_code_email');
          } else {
            setToken(res.token);
            setForgottenState(3);
            setLoading(false);
          }
        })
        .catch(err => {
          setError('general', {
            message:
              'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
          });
          console.warn(err);
          setLoading(false);
        });
    },
    [setError]
  );

  const onResetPassword = useCallback(
    (email, token, newPassword) => {
      setLoading(true);

      request
        .post(config.adaptiveUrl + 'api/Auth/ResetPassword')
        .set('X-Adaptive-variant', 'routeinfo')
        .send({
          token,
          newPassword,
          email
        })
        .then(() => {
          setToken('');
          setForgottenState(4);
          setLoading(false);
        })
        .catch(err => {
          if (err.response && err.response.statusCode) {
            switch (err.response.statusCode) {
              case 401:
                setError('general', {
                  message: 'The email address or password you entered was incorrect.'
                });
                break;

              default:
                setError('general', {
                  message:
                    'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
                });
                break;
            }
          } else {
            setError('general', {
              message:
                'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
            });
            console.warn(err);
          }
          setLoading(false);
        });
    },
    [setError]
  );

  const onSubmit = useCallback(
    data => {
      switch (forgottenState) {
        case 0:
          onLogin(data.email, data.password);
          break;

        case 1:
          onVerifyBegin(data.email);
          break;

        case 2:
          onVerify(email, data.code);
          break;

        case 3:
          onResetPassword(email, token, data.newPassword);
          break;

        default:
          setForgottenState(0);
          break;
      }
    },
    [forgottenState, onLogin, onResetPassword, onVerify, onVerifyBegin, email, token]
  );

  if (useContext(UserContext) || shouldRedirect) {
    return <Navigate to={from || '/profile'} />;
  }

  return (
    <div className="userForm-container">
      <div className="userForm-content" id="formView-scrollPane">
        {loading && <LoadingMask />}
        <Title title="Login - Kystverket" />
        <h2>Login</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          {forgottenState < 2 && (
            <div className="form-group">
              <label htmlFor="email">E-mail address: * </label>
              <input
                {...register('email', {
                  required: 'This field is required.',
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: 'Email you have entered is not valid.'
                  },
                  maxLength: {
                    value: 70,
                    message: 'Cannot be longer than 70 characters.'
                  }
                })}
                name="email"
                type="email"
                className={['form-control', errors.email ? 'error' : ''].join(' ')}
                placeholder="Enter your e-mail"
                onChange={e => setEmail(e.target.value)}
              />
              {errors.email && (
                <small
                  className="form-control--error"
                  dangerouslySetInnerHTML={{ __html: errors.email.message }}
                />
              )}
            </div>
          )}
          {forgottenState === 0 && (
            <div className="form-group">
              <label htmlFor="password">Password: * </label>
              <input
                {...register('password', {
                  required: 'This field is required.'
                })}
                type="password"
                name="password"
                className={['form-control', errors.password ? 'error' : ''].join(' ')}
                placeholder="Enter your password"
              />
              {errors.password && (
                <small
                  className="form-control--error"
                  dangerouslySetInnerHTML={{ __html: errors.password.message }}
                />
              )}
            </div>
          )}
          {forgottenState === 2 && (
            <div className="form-group">
              <label htmlFor="text">Verify one time code: * </label>
              <input
                {...register('code', {
                  required: 'This field is required.',
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Email you have entered is not valid.'
                  },
                  maxLength: {
                    value: 6,
                    message: 'Cannot be longer than 6 characters.'
                  }
                })}
                type="text"
                name="code"
                className={['form-control', errors.code ? 'error' : ''].join(' ')}
                placeholder="Enter one time code"
              />
              {errors.code && (
                <small
                  className="form-control--error"
                  dangerouslySetInnerHTML={{ __html: errors.code.message }}
                />
              )}
            </div>
          )}
          {forgottenState === 3 && (
            <div className="form-group">
              <label htmlFor="password">New password: * </label>
              <input
                {...register('password', {
                  required: 'This field is required.',
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{4,}$/gm,
                    message:
                      '<div>Password must contain:  <ul><li>at least one upper case letter,</li> <li> at least one lower case letter,</li> <li>at least one digit,</li><li>at least one special character,</li><li>and must be longer than 4 characters</li></ul><div>'
                  }
                })}
                type="password"
                name="newPassword"
                className={['form-control', errors.newPassword ? 'error' : ''].join(' ')}
                placeholder="Enter your new password"
              />
              {errors.newPassword && (
                <small
                  className="form-control--error"
                  dangerouslySetInnerHTML={{ __html: errors.newPassword.message }}
                />
              )}
            </div>
          )}

          {forgottenState === 4 && <p>Password changed</p>}
          {errors && errors.general && (
            <p
              className="form-control--error"
              dangerouslySetInnerHTML={{ __html: errors.general.message }}
            />
          )}
          {forgottenState > 0 && (
            <button
              type="button"
              className={`btn ${forgottenState === 4 ? 'btn-primary' : 'btn-secondary'}`}
              onClick={() => setForgottenState(0)}
            >
              {forgottenState === 4 ? 'Login' : 'Cancel'}
            </button>
          )}

          {forgottenState < 4 && (
            <button type="submit" className="btn btn-primary" onClick={() => clearErrors()}>
              {forgottenState === 1
                ? 'Send code'
                : forgottenState === 2
                ? 'Verify Code'
                : forgottenState === 3
                ? 'Set new password'
                : 'Login'}
            </button>
          )}

          <div className="below">
            <Link className="btn-link" to="/register">
              Don't have an account? Sign Up
            </Link>
            {forgottenState === 0 && (
              <button
                className="btn-link"
                onClick={e => {
                  e.preventDefault();
                  setForgottenState(1);
                }}
              >
                Forgot your password?
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
