export function stringToDate(date) {
  if (date && typeof date === 'string') {
    if (date.indexOf('/Date') === 0) {
      date = date.replace('/Date(', '');
      date = date.replace(')/', '');
      return new Date(parseInt(date, 10) || date);
    } else {
      return new Date(date);
    }
  } else {
    return undefined;
  }
}

export function buildRouteName({ routeQualityStatus, routeName, vps }) {
  if (!(routeQualityStatus && routeName && vps)) return '';
  const vpsDate = vps;
  const validityPeriodStart =
    vpsDate.getFullYear() +
    '' +
    (vpsDate.getUTCMonth() + 1 < 10
      ? 0 + '' + (vpsDate.getUTCMonth() + 1)
      : vpsDate.getUTCMonth() + 1) +
    '' +
    (vpsDate.getUTCDate() < 10 ? 0 + '' + vpsDate.getUTCDate() : vpsDate.getUTCDate());

  return `${routeQualityStatus}_${routeName}_${validityPeriodStart}.rtz`;
}
