import React, { useState, useContext, useCallback } from 'react';
import Result from './SearchResults';
import useSearch from '../../hooks/useSearch';
import usePopup from '../../hooks/usePopup';
import { MapContext } from '@avinet/react-openlayers';
import { useNavigate } from 'react-router-dom';
import './Search.scss';

const Search = ({ placeholder = 'Search', className }) => {
  const [query, setQuery] = useState('');
  const [ref, isVisible, setVisible] = usePopup();

  const { map } = useContext(MapContext);
  const navigate = useNavigate();

  const mapExtent = map.getView().calculateExtent(map.getSize());

  const { items } = useSearch({ query, mapExtent });

  const onSelect = useCallback(
    item => {
      navigate('/view/' + item.type + '/' + item.uid.split('.')[2]);
      setVisible(false);
    },
    [navigate, setVisible]
  );

  const onDismiss = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <div className={['search--container', className || ''].join(' ')} ref={ref}>
      <div className="search--inputWrap">
        <input
          className="form-control"
          placeholder={placeholder}
          type="search"
          onChange={e => setQuery(e.target.value)}
          onFocus={() => setVisible(true)}
          value={query}
        />
        <button
          title="Clear search"
          type="button"
          className="btn-link clear-search"
          onClick={() => setQuery('')}
        >
          <span className="glyphicon glyphicon-remove" />
        </button>
      </div>

      {isVisible && items && (
        <div tabIndex={0} className="overlay" onFocus={onDismiss} onClick={onDismiss} />
      )}

      {isVisible && items && <Result results={items} onSelect={onSelect} isVisible={isVisible} />}
    </div>
  );
};

export default Search;
