import React, { useState, useRef } from 'react';
import { usePage } from '@avinet/react-adaptive-cms';
import Cookies from 'cookies-js';
import './Disclaimer.scss';

const hasAccepted = () => Cookies.get('disclaimerAccept') === 'y';

const accept = () => Cookies.set('disclaimerAccept', 'y', { expires: 30 * 24 * 60 * 60 });

const Disclaimer = () => {
  const page = usePage('disclaimer');
  const [warn, setWarn] = useState(false);
  const acceptRef = useRef(null);

  const dismiss = () => {
    if (acceptRef.current && !acceptRef.current.checked) {
      setWarn({ warn: true });
    } else {
      accept();
      setWarn({ done: true });
    }
  };

  return hasAccepted() ? null : (
    <div className="disclaimer--container">
      <div>
        <div dangerouslySetInnerHTML={{ __html: page?.content }} />
        <div className={'bar ' + (warn ? 'warning ' : '')}>
          <div className="checkbox">
            <label>
              <input type="checkbox" ref={acceptRef} /> I have read and accept these conditions
            </label>
          </div>
          <button className="btn btn-primary" onClick={dismiss}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
