import React, { useMemo, useCallback } from 'react';
import useDataSource from '../../hooks/useDataSource';
import useLocations from '../../hooks/useLocations';
import { useNavigate } from 'react-router-dom';
import LoadingDots from '../loading/LoadingDots';
import { olUtils, VectorSource, defaultStyle, defaultText } from '@avinet/react-openlayers';
import { SourceIndexes } from '../../config/SourceConfig';

import config from '../../config/kystverket';

import './RouteAfter.scss';

function getMediaUrl(uuid, title = '') {
  return (
    config.adaptiveUrl +
    'WebServices/generic/Media.asmx/Download?uuid=' +
    uuid +
    '&filename=' +
    title
  );
}

function getRtzUrl(routenumber) {
  return config.adaptiveUrl + '/api/kystverket/routeinfo/v2/routes/' + routenumber + '/download';
}

const MtrAfter = ({ filter }) => {
  const { loading, items } = useDataSource({ type: SourceIndexes.MTR, filter });
  const navigate = useNavigate();

  const onClick = useCallback(item => navigate('/view/mtr/' + item.id), [navigate]);

  return (
    <div>
      <h4>Local Regulations (VTS)</h4>
      {loading && <LoadingDots />}
      {!loading && items && (
        <VectorSource
          layerName="route_mtr"
          features={items.map(item => item.olFeature) || []}
          selectable={false}
          useHoverStyle
          style={
            new defaultStyle({
              fillColor: 'rgba(255,255,255,0)',
              hoverColor: 'rgba(0,153,255, .3)',
              strokeColor: 'rgba(255,255,255,.5)',
              strokeWidth: 3,
              labelText: defaultText({
                labelProperty: 'section'
              })
            })
          }
        />
      )}
      {!loading &&
        items &&
        items.map(item => (
          <p key={`mtr_${item.id}`} className="mtr subList">
            <button
              className="btn btn-link"
              onMouseOver={() => item.olFeature.set('hover', true)}
              onMouseOut={() => item.olFeature.set('hover', false)}
              onClick={() => onClick(item)}
            >
              {item.section}
            </button>
          </p>
        ))}
      {!loading && items && items.length === 0 && <p>None</p>}
    </div>
  );
};

const VtsAfter = ({ filter }) => {
  const { loading, items } = useDataSource({ type: SourceIndexes.VTS, filter });

  return (
    <div>
      <h4>VTS information</h4>
      {loading && <LoadingDots />}
      {!loading &&
        items &&
        items.map(item => (
          <p key={`vts_${item.id}`}>
            {item.areaname_eng},{' '}
            <a
              href={getMediaUrl(item.factsheet, item.areaname_eng + '.pdf')}
              target="_blank"
              rel="noopener noreferrer"
            >
              Fact sheet
            </a>
          </p>
        ))}
      {!loading && items && items.length === 0 && <p>None</p>}
    </div>
  );
};

const PortsAfter = ({ portIds }) => {
  const { items, loading } = useLocations({ ids: portIds, type: SourceIndexes.PORT });

  return (
    <div className="additional">
      <h5>Ports incl. anchorage</h5>
      {loading && <LoadingDots />}
      {!loading && items && (
        <p>
          {items && items.length
            ? items.map(l => `${l.locationnameeng} (${l.councilname})`).join(', ')
            : 'None'}
        </p>
      )}
    </div>
  );
};

const PortFacilityAfter = ({ portfacilityIds }) => {
  const { items, loading } = useLocations({
    ids: portfacilityIds,
    type: SourceIndexes.PORT_FACILITY
  });

  return (
    <div className="additional">
      <h5>Port facilities</h5>
      {loading && <LoadingDots />}
      {!loading && items && (
        <p>
          {items && items.length
            ? items.map(l => `${l.locationnameeng} (${l.councilname})`).join(', ')
            : 'None'}
        </p>
      )}
    </div>
  );
};

const QuayAfter = ({ quayIds }) => {
  const { items, loading } = useLocations({ ids: quayIds, type: SourceIndexes.QUAY });

  return (
    <div className="additional">
      <h5>Quays</h5>
      {loading && <LoadingDots />}
      {!loading && items && (
        <p>
          {items && items.length
            ? items.map(l => `${l.locationnameeng} (${l.councilname})`).join(', ')
            : 'None'}
        </p>
      )}
    </div>
  );
};

const CprAfter = ({ filter }) => {
  const { loading, items } = useDataSource({ type: SourceIndexes.CPR, filter });
  const navigate = useNavigate();

  const onClick = useCallback(item => navigate('/view/cpr/' + item.id), [navigate]);

  return (
    <div>
      <h4>Local Regulations for Use of Pilot Exemption Certificate (PEC)</h4>
      {loading && <LoadingDots />}
      {!loading && (
        <VectorSource
          layerName="route_cpr"
          features={(items && items.map(item => item.olFeature)) || []}
          selectable={false}
          useHoverStyle
          style={
            new defaultStyle({
              fillColor: 'rgba(255,255,255,0)',
              hoverColor: 'rgba(0,153,255, .3)',
              strokeColor: 'rgba(255,255,255,.5)',
              strokeWidth: 3,
              labelText: defaultText({
                labelProperty: 'name'
              })
            })
          }
        />
      )}
      {!loading &&
        items &&
        items.map(item => (
          <p key={`vts_${item.id}`} className="restriction subList">
            <button
              className="btn btn-link"
              onMouseOver={() => item.olFeature.set('hover', true)}
              onMouseOut={() => item.olFeature.set('hover', false)}
              onClick={() => onClick(item)}
            >{`${item.name} ${item.maritimedivisionname}`}</button>
          </p>
        ))}
      {!loading && (!items || items.length === 0) && '150 meter LOA'}
    </div>
  );
};

const SvalbardAfter = ({ filter }) => {
  const { loading, items } = useDataSource({ type: SourceIndexes.SVALBARD, filter });
  const navigate = useNavigate();

  const onClick = useCallback(item => navigate('/view/svalbard/' + item.id), [navigate]);

  return (
    <div>
      {items && items.length !== 0 ? (
        <div>
          <h4>Svalbard, Regulations and Information Services</h4>
          {loading && <LoadingDots />}
          {!loading && (
            <VectorSource
              layerName="route_svalbard"
              features={(items && items.map(item => item.olFeature)) || []}
              selectable={false}
              useHoverStyle
              style={
                new defaultStyle({
                  fillColor: 'rgba(255,255,255,0)',
                  hoverColor: 'rgba(0,153,255, .3)',
                  strokeColor: 'rgba(255,255,255,.5)',
                  strokeWidth: 3,
                  labelText: defaultText({
                    labelProperty: 'name'
                  })
                })
              }
            />
          )}
          {!loading &&
            items.map(item => (
              <p key={`svalbard_${item.id}`} className="restriction subList">
                <button
                  className="btn btn-link"
                  onMouseOver={() => item.olFeature.set('hover', true)}
                  onMouseOut={() => item.olFeature.set('hover', false)}
                  onClick={() => onClick(item)}
                >{`${item.name}`}</button>
              </p>
            ))}
        </div>
      ) : null}
    </div>
  );
};

const RouteAfter = ({
  id,
  routeName,
  routeNumber,
  olFeature,
  autoLength,
  portIds,
  portfacilityIds,
  quayIds
}) => {
  const filter = useMemo(() => {
    return {
      filterColumns: [
        {
          name: 'geom',
          value:
            'SRID=' +
            config.dataProjCode +
            ';' +
            olUtils.getWktFromFeature(
              olUtils.createFeatureFromGeometry(
                olFeature
                  .getGeometry()
                  .clone()
                  .transform('EPSG:' + config.mapProjCode, 'EPSG:' + config.dataProjCode)
              )
            ),
          comparisonOperator: 'ST_INTERSECTS',
          netType: 'geometry',
          logicalOperator: 'AND'
        }
      ]
    };
  }, [olFeature]);

  return (
    <div className="route-after--root">
      <div className="additional">
        <h5>Routename</h5>
        <p>{routeName}</p>
      </div>
      {id && (
        <p>
          {id && (
            <a href={getRtzUrl(routeNumber)} download={routeName} className="btn btn-info">
              Download RTZ 1.0
            </a>
          )}
        </p>
      )}
      <h3>Route Info</h3>
      <SvalbardAfter filter={filter} />
      <div className="additional">
        <h5>Sailing Distance</h5>
        <p>{(autoLength / 1852).toFixed(1)} nautical miles</p>
      </div>
      <CprAfter filter={filter} />
      <MtrAfter filter={filter} />
      <VtsAfter filter={filter} />
      <h4>Connected locations</h4>
      <PortsAfter portIds={portIds} />
      <PortFacilityAfter portfacilityIds={portfacilityIds} />
      <QuayAfter quayIds={quayIds} />
    </div>
  );
};

export default RouteAfter;
