import { useMemo, useContext } from 'react';
import { DataContext } from '../contexts/DataContext';
import config from '../config/kystverket';

const useItem = ({ type, id }) => {
  const { items, loading } = useContext(DataContext);

  const item = useMemo(() => {
    if (!items) return;

    const idKey = config.themes[type].id;

    return items.find(i => i.type === type && i[idKey] === id);
  }, [items, type, id]);

  return { loading, item };
};

export default useItem;
