import React from 'react';
import { Map, WmsLayer, Zoom, XYZLayer } from '@avinet/react-openlayers';
import Legend from './Legend';
import config from '../../config/kystverket';
import BasemapToggle from './BasemapToggle';
import useSyncedLocalStorage from '../../hooks/useSyncedLocalStorage';
import LayerSwitch from './LayerSwitch';

const DefaultMap = ({ children, initalExtent }) => {
  const [mode, setMode] = useSyncedLocalStorage('mapMode');

  return (
    <Map
      track
      trackTipLabel="Zoom to your location"
      extent={initalExtent || config.extent}
      minZoom={4}
      maxZoom={18}
      projDefs={[
        {
          epsg: 'EPSG:32633',
          def: '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs'
        }
      ]}
      baatUrl={config.adaptiveUrl}
      hideScaleLine
    >
      <Zoom />
      <BasemapToggle mode={mode} setMode={setMode} />
      <LayerSwitch />
      <WmsLayer
        id="world"
        key="world"
        name="Verden"
        uri="https://map-adaptive3.avinet.no/mapserv.ashx?map=public/world&amp;color=241 241 241"
        layerName="ocean_3857"
        version="1.3.0"
        zIndex={-11}
        crossOrigin="Anonymous"
        singleTile
      />

      {mode === 'coast' ? (
        <XYZLayer
          id="sjokart"
          key="sjokart"
          name="Sea map"
          url="https://cache.kartverket.no/v1/wmts/1.0.0/sjokartraster/default/webmercator/{z}/{y}/{x}.png"
          zIndex={-1}
        />
      ) : mode === 'enc' ? (
        <WmsLayer
          id="enc"
          name="ENC Norge"
          uri="https://a3cloud.kystverket.no/proxies/xEncProxy.ashx"
          //uri="https://kart.kystverket.no/proxies/xEncProxy.ashx" //default --> seems to be the same as style-id-260, and all others...
          //uri="https://kart.kystverket.no/proxies/xEncProxy.ashx?style=style-id-260" //Need proxy cause only allowed ip's can request data
          //uri="https://wms.geonorge.no/skwms1/wms.ecc_enc" //default --> seems to be the same as style-id-260, and all others...
          //uri="https://wms.geonorge.no/skwms1/wms.ecc_enc?styles=style-id-260" //Full with ECDIS chart symbols
          //uri="https://wms.geonorge.no/skwms1/wms.ecc_enc?styles=style-id-2142" //Full with simplified symbols and transparent land
          //uri="https://wms.geonorge.no/skwms1/wms.ecc_enc?styles=style-id-2475" //Full with simplified symbols and transparent land
          layerName="cells"
          version="1.1.1"
          zIndex={-1}
          singleTile //seems faster than multi-tile
          key="enc-norge"
        />
      ) : (
        <XYZLayer
          id="graatone"
          key="graatone"
          name="Grey Basemap"
          url="https://cache.kartverket.no/v1/wmts/1.0.0/topograatone/default/webmercator/{z}/{y}/{x}.png"
          zIndex={-1}
        />
      )}
      {children}
      <Legend />
    </Map>
  );
};

export default DefaultMap;
