import React from 'react';
import PropTypes from 'prop-types';
import { MapPadding } from '@avinet/react-openlayers';
import { provideViewSize } from '../hocs/provideViewSize';
import NavBar from './NavBar';
import './CoreLayout.scss';
import Banner from '../components/Banner';
import config from '../config/kystverket';

export class CoreLayout extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    viewWidth: PropTypes.number,
    viewHeight: PropTypes.number
  };

  calcMapPadding() {
    const { viewHeight, viewWidth } = this.props;
    if (viewWidth < 769) {
      return [50, 0, (6 * viewHeight) / 10, 0];
    }
    if (viewWidth < 1260) {
      return [0, 400, 0, 0];
    }
    return [0, 440, 0, 0];
  }

  render() {
    const { children } = this.props;
    return (
      <div className="core-layout--root">
        <MapPadding padding={this.calcMapPadding()} />
        <NavBar />
        <div className="kv-logo">
          <img src="/kvlogo.png" alt="" />
        </div>
        <div className="core-layout--components">{children}</div>
        <Banner
          name="gdpr-disclaimer"
          textContent="This site stores your settings via local storage in your browser. You may erase this
              by deleting your browser's history."
        />
        {config.isDevSite && (
          <div className="dev-banner">
            This is a development site! Uses dev backend: {config.adaptiveUrl}
          </div>
        )}
      </div>
    );
  }
}

export default provideViewSize(CoreLayout);
