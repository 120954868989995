import React from 'react';
import Title from '../components/Title';
import './ArticleView.scss';
import { provideMapState } from "@avinet/react-openlayers";
import ArticleList from '../components/article/ArticleList';

const ArticleView = ({ mapZoom, mapExtent, mapCenter }) => {
  return (
    <div className="article-view--container">
      <Title title="Digital route service for navigation" />
      <div className="article-view--content" id="articleView-scrollPane">
        {/* {mapZoom <= 12 ? (
          <div className="article-view--info">
            Find reference routes by searching for port/port facility/quay or route name in the
            search field above, or navigate and select directly in the map
          </div>
        ) : ( */}
        <ArticleList mapExtent={mapExtent} mapCenter={mapCenter} />
        {/* )} */}
      </div>
    </div>
  );
};

export default provideMapState(ArticleView);
