import React, { useCallback, useEffect, useState } from 'react';
import Icon from './Icon';

const Banner = ({ name, textContent }) => {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const bannerDeactivated = sessionStorage.getItem(`${name}_deactivated`);

    if (bannerDeactivated) setShowBanner(false);
  }, [name]);

  const onBannerVisibilityChange = useCallback(() => {
    sessionStorage.setItem(`${name}_deactivated`, true);
    setShowBanner(!showBanner);
  }, [name, showBanner]);

  if (!showBanner) {
    return null;
  } else {
    return (
      <div className={name}>
        <span className="text">{textContent}</span>
        <button onClick={onBannerVisibilityChange}>
          <Icon name="cross" />
        </button>
      </div>
    );
  }
};

export default Banner;
