import React, { useCallback, useState } from 'react';
import useItems from '../../hooks/useItems';
import { SourceIndexes, titlePrefix } from '../../config/SourceConfig';
import { featureStyle } from '../../utils/featureStyle';
import { useNavigate } from 'react-router-dom';
import { VectorSource } from '@avinet/react-openlayers';
import config from '../../config/kystverket';
import './ArticleList.scss';

const Port = ({ locationid, locationnameeng, olFeature, onMouseOver, onMouseOut }) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(config.themes[SourceIndexes.PORT].url + locationid);
  }, [navigate, locationid]);

  return (
    <div
      className="article port"
      onClick={onClick}
      onMouseOver={() => onMouseOver(olFeature)}
      onMouseOut={onMouseOut}
    >
      <h4>{titlePrefix(SourceIndexes.PORT) + locationnameeng}</h4>
    </div>
  );
};

const PortFacility = ({ locationid, locationnameeng, olFeature, onMouseOut, onMouseOver }) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(config.themes[SourceIndexes.PORT_FACILITY].url + locationid);
  }, [navigate, locationid]);

  return (
    <div
      className="article portfacility"
      onClick={onClick}
      onMouseOver={() => onMouseOver(olFeature)}
      onMouseOut={onMouseOut}
    >
      <h4>{titlePrefix(SourceIndexes.PORT_FACILITY) + locationnameeng}</h4>
    </div>
  );
};

const Route = ({ id, routeinfo_routename, olFeature, onMouseOver, onMouseOut }) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(config.themes[SourceIndexes.ROUTE].url + id);
  }, [navigate, id]);

  return (
    <div
      className="article route"
      onClick={onClick}
      onMouseOver={() => onMouseOver(olFeature)}
      onMouseOut={onMouseOut}
    >
      <h4>{titlePrefix(SourceIndexes.ROUTE) + routeinfo_routename}</h4>
    </div>
  );
};

const Quay = ({ locationid, locationnameeng, olFeature, onMouseOver, onMouseOut }) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(config.themes[SourceIndexes.QUAY].url + locationid);
  }, [locationid, navigate]);

  return (
    <div
      className="article quay"
      onClick={onClick}
      onMouseOver={() => onMouseOver(olFeature)}
      onMouseOut={onMouseOut}
    >
      <h4>{titlePrefix(SourceIndexes.QUAY) + locationnameeng}</h4>
    </div>
  );
};

const MTR = ({ id, section, olFeature, onMouseOut, onMouseOver }) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(config.themes[SourceIndexes.MTR].url + id);
  }, [id, navigate]);

  return (
    <div
      className="article mtr"
      onClick={onClick}
      onMouseOver={() => onMouseOver(olFeature)}
      onMouseOut={onMouseOut}
    >
      <h4>{titlePrefix(SourceIndexes.MTR) + section}</h4>
    </div>
  );
};

const CPR = ({ id, name, c1_dp, sok_kl1, olFeature, onMouseOver, onMouseOut }) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(config.themes[SourceIndexes.CPR].url + id);
  }, [id, navigate]);

  return (
    <div
      className={[
        'article',
        'cpr',
        c1_dp === 0 ? 'red' : c1_dp === 1 && sok_kl1 !== 0 ? 'blue' : ''
      ].join(' ')}
      onClick={onClick}
      onMouseOver={() => onMouseOver(olFeature)}
      onMouseOut={onMouseOut}
    >
      <h4>{titlePrefix(SourceIndexes.CPR) + name}</h4>
    </div>
  );
};

const Svalbard = ({ id, name, olFeature, onMouseOver, onMouseOut }) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(config.themes[SourceIndexes.SVALBARD].url + id);
  }, [id, navigate]);

  return (
    <div
      className="article svalbard"
      onClick={onClick}
      onMouseOver={() => onMouseOver(olFeature)}
      onMouseOut={onMouseOut}
    >
      <h4>{titlePrefix(SourceIndexes.SVALBARD) + name}</h4>
    </div>
  );
};

const ArticleList = ({ mapExtent, mapCenter }) => {
  const { loading, items } = useItems({ mapExtent, mapCenter });
  const [activeFeature, setActiveFeature] = useState();

  const onMouseOver = useCallback(f => {
    setActiveFeature(f);
  }, []);

  const onMouseOut = useCallback(f => {
    setActiveFeature(null);
  }, []);

  return (
    <div className="article-list">
      {activeFeature && (
        <VectorSource
          layerName="hover"
          features={[activeFeature]}
          selectable={false}
          style={featureStyle}
        />
      )}
      {!loading &&
        items.slice(0, 50).map(i => {
          switch (i.type) {
            case SourceIndexes.PORT:
              return (
                <Port
                  key={`port_${i.locationid}`}
                  {...i}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                />
              );
            case SourceIndexes.PORT_FACILITY:
              return (
                <PortFacility
                  key={`portfacility_${i.locationid}`}
                  {...i}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                />
              );
            case SourceIndexes.ROUTE:
              return (
                <Route
                  key={`route_${i.id}`}
                  {...i}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                />
              );
            case SourceIndexes.QUAY:
              return (
                <Quay
                  key={`quay_${i.locationid}`}
                  {...i}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                />
              );
            case SourceIndexes.MTR:
              return (
                <MTR key={`mtr${i.id}`} {...i} onMouseOver={onMouseOver} onMouseOut={onMouseOut} />
              );
            case SourceIndexes.CPR:
              return (
                <CPR key={`cpr${i.id}`} {...i} onMouseOver={onMouseOver} onMouseOut={onMouseOut} />
              );
            case SourceIndexes.SVALBARD:
              return (
                <Svalbard
                  key={`svalbard${i.id}`}
                  {...i}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                />
              );
            default:
              return '';
          }
        })}
    </div>
  );
};

export default ArticleList;
