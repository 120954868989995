import React from 'react';
import './Icon.scss';

const icons = {
  search: (
    <svg viewBox="0 0 32 32">
      <path d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z" />
    </svg>
  ),
  checkmark: (
    <svg viewBox="0 0 32 32">
      <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" />
    </svg>
  ),
  check: (
    <svg viewBox="0 0 16 14">
      <g fill="currentColor" transform="translate(-4, -4)">
        <polygon points="9.09 15.47 5.29 11.74 4 13 9.09 18 20 7.26 18.72 6 9.09 15.47" />
      </g>
    </svg>
  ),
  chevron: (
    <svg viewBox="0 0 24 24">
      <g fill="currentColor" transform="translate(0,24) rotate(-90)">
        <path d="M17.29,8.29h0L12,13.59,6.71,8.29A1,1,0,0,0,5.29,9.71L12,16.41l6.71-6.7a1,1,0,0,0-1.42-1.42Z" />
      </g>
    </svg>
  ),
  cross: (
    <svg viewBox="0 0 16 16">
      <path d="M13.957 3.457l-1.414-1.414-4.543 4.543-4.543-4.543-1.414 1.414 4.543 4.543-4.543 4.543 1.414 1.414 4.543-4.543 4.543 4.543 1.414-1.414-4.543-4.543z" />
    </svg>
  ),
  share: (
    <svg viewBox="0 0 32 32">
      <path d="M8 20c0 0 1.838-6 12-6v6l12-8-12-8v6c-8 0-12 4.99-12 10zM22 24h-18v-12h3.934c0.315-0.372 0.654-0.729 1.015-1.068 1.374-1.287 3.018-2.27 4.879-2.932h-13.827v20h26v-8.395l-4 2.667v1.728z" />
    </svg>
  ),
  envelope: (
    <svg viewBox="0 0 20 20">
      <path d="M18 2c1.105 0 2 0.895 2 2v0 12c0 1.105-0.895 2-2 2v0h-16c-1.105 0-2-0.895-2-2v0-12c0-1.1 0.9-2 2-2h16zM13.63 11.1l6.37 4.9v-2l-5.12-3.9 5.12-4.1v-2l-10 8-10-8v2l5.12 4.1-5.12 3.9v2l6.37-4.9 3.63 2.9 3.63-2.9z" />
    </svg>
  ),
  load: (
    <svg viewBox="0 0 32 32">
      <path d="M12 4c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM20.485 7.515c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM26 16c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM22.485 24.485c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM14 28c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM5.515 24.485c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM4.515 7.515c0 0 0 0 0 0 0-1.657 1.343-3 3-3s3 1.343 3 3c0 0 0 0 0 0 0 1.657-1.343 3-3 3s-3-1.343-3-3zM1.75 16c0-1.243 1.007-2.25 2.25-2.25s2.25 1.007 2.25 2.25c0 1.243-1.007 2.25-2.25 2.25s-2.25-1.007-2.25-2.25z" />
    </svg>
  ),
  chevronRight: (
    <svg viewBox="0 0 16 16">
      <path d="M5.5 0l-2 2 6 6-6 6 2 2 8-8-8-8z" />
    </svg>
  ),
  chevronLeft: (
    <svg viewBox="0 0 16 16">
      <path d="M10.5 16l2-2-6-6 6-6-2-2-8 8 8 8z" />
    </svg>
  ),
  bookmark: (
    <svg viewBox="0 0 32 32">
      <path d="M6 0v32l10-10 10 10v-32z" />
    </svg>
  ),
  map: (
    <svg viewBox="0 0 32 28">
      <path d="M31.563 0.172c0.266 0.187 0.438 0.5 0.438 0.828v22c0 0.406-0.25 0.781-0.625 0.922l-10 4c-0.25 0.109-0.5 0.109-0.75 0l-9.625-3.844-9.625 3.844c-0.125 0.063-0.25 0.078-0.375 0.078-0.203 0-0.391-0.063-0.562-0.172-0.266-0.187-0.438-0.5-0.438-0.828v-22c0-0.406 0.25-0.781 0.625-0.922l10-4c0.25-0.109 0.5-0.109 0.75 0l9.625 3.844 9.625-3.844c0.313-0.125 0.656-0.094 0.938 0.094zM11.5 2.281v19.844l9 3.594v-19.844zM2 5.672v19.844l8.5-3.391v-19.844zM30 22.328v-19.844l-8.5 3.391v19.844z" />
    </svg>
  ),
  pin: (
    <svg viewBox="0 0 16 16">
      <path d="M8 0c-2.761 0-5 2.239-5 5 0 5 5 9 5 9s5-4 5-9c0-2.761-2.239-5-5-5zM8 8.063c-1.691 0-3.063-1.371-3.063-3.063s1.371-3.063 3.063-3.063 3.063 1.371 3.063 3.063-1.371 3.063-3.063 3.063zM6.063 5c0 1.070 0.867 1.938 1.938 1.938s1.938-0.867 1.938-1.938-0.867-1.938-1.938-1.938c-1.070 0-1.938 0.867-1.938 1.938zM12.285 10.9c-0.222 0.348-0.451 0.678-0.682 0.988 0.037 0.017 0.073 0.035 0.108 0.052 0.76 0.38 1.101 0.806 1.101 1.059s-0.34 0.679-1.101 1.059c-0.957 0.479-2.31 0.753-3.712 0.753s-2.754-0.275-3.712-0.753c-0.76-0.38-1.101-0.806-1.101-1.059s0.34-0.679 1.101-1.059c0.036-0.018 0.072-0.035 0.108-0.052-0.231-0.31-0.461-0.64-0.682-0.988-1.061 0.541-1.715 1.282-1.715 2.1 0 1.657 2.686 3 6 3s6-1.343 6-3c0-0.817-0.654-1.558-1.715-2.1z" />
    </svg>
  ),
  chevrondown: (
    <svg viewBox="0 0 24 24">
      <path d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z" />
    </svg>
  ),
  chevronup: (
    <svg viewBox="0 0 24 24">
      <path d="M18.707 14.293l-6-6c-0.391-0.391-1.024-0.391-1.414 0l-6 6c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z" />
    </svg>
  ),
  layerSwitch: (
    <svg viewBox="0 0 22.1 23.4">
      <g>
        <path d="M11.1,16.3C7.9,13.9,4.8,11.5,1.6,9l9.4-7.3L20.5,9L11.1,16.3z M11.1,19l7.7-6l1.7,1.3l-9.4,7.3l-9.4-7.3L3.3,13L11.1,19z" />
      </g>
    </svg>
  )
};

/**
 *
 * @param {*} props
 * @param {'layers'|'bags'} props.name Name of icon
 */
const Icon = ({ name, rotate, title, className = null }) => (
  <span
    className={['icon', rotate ? 'r' + rotate : '', className ? className : ''].join(' ')}
    title={title}
  >
    {icons[name] || icons.missing}
  </span>
);

export const IconList = () => {
  return (
    <div className="icon-list">
      {Object.keys(icons)
        .sort()
        .filter(name => name !== 'missing')
        .map(name => (
          <div key={name}>
            <Icon name={name} /> {name}
          </div>
        ))}
    </div>
  );
};

export default Icon;
