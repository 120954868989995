import React, { useState, useEffect, useCallback, useMemo } from 'react';
import XRegExp from 'xregexp';
import request from 'superagent';
import LoadingDots from './loading/LoadingDots';
import LoadingMask from './loading/LoadingMask';
import config from '../config/kystverket';
import pubsub from '../utils/pubsub';
import useSessionId from '../hooks/useSessionId';
import { useForm } from 'react-hook-form';
import { setSessionId } from '../utils/sesionId';

const ProfileEditor = ({ user, onSaved }) => {
  const [sessionId] = useSessionId();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [consentEmail, setConsentEmail] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { register, handleSubmit, errors, setError, watch, setValue } = useForm({
    mode: 'onChange'
  });

  const watchAll = watch();

  useEffect(() => {
    setLoading(true);

    request
      .post(config.adaptiveUrl + 'WebServices/generic/Privacy.asmx/Consents')
      .set(`gm_session_id`, sessionId)
      .send({})
      .then(res => res.body)
      .then(res => {
        if (!res.d.success) {
          console.error(res.d);
          return;
        }

        setLoading(false);

        res.d.records.forEach(r => {
          if (r.category === config.accept_email_consent) {
            setConsentEmail(true);
          }
        });
      })
      .catch(err => {
        console.error(err);
      });
  }, [sessionId]);

  const isDirty = useMemo(
    () =>
      watchAll.fname !== user.userData.first_name ||
      watchAll.lname !== user.userData.last_name ||
      watchAll.company !== user.userData.company ||
      watchAll.email !== user.email ||
      watchAll.tel !== user.userData.phone,
    [user, watchAll.fname, watchAll.lname, watchAll.company, watchAll.email, watchAll.tel]
  );

  useEffect(() => {
    setLoading(true);
    if (user) {
      setValue('email', user.email);
      setValue('fname', user.userData.first_name);
      setValue('lname', user.userData.last_name);
      setValue('company', user.userData.company);
      setValue('tel', user.userData.phone);
      setLoading(false);
    }
  }, [user, setValue]);

  const onSubmit = useCallback(
    data => {
      if (!isDirty) return;

      const userData = {
        ...user.userData,
        first_name: data.fname,
        last_name: data.lname,
        company: data.company,
        email: data.email,
        phone: data.tel
      };

      setLoading(true);
      setSaving(true);

      request
        .put(config.adaptiveUrl + 'api/Profile/me') // <== use for prod
        // .put('http://localhost/a_a3/api/Profile/me')
        .set('Authorization', 'Bearer ' + sessionId)
        .send(userData)
        .then(res => res.body)
        .then(() => {
          setSaving(false);
          setLoading(false);
          pubsub.publish('profile/saved');
          onSaved();
        })
        .catch(err => {
          if (err.response && err.response.statusCode) {
            switch (err.response.statusCode) {
              case 409:
                setError('email', { message: 'There is already a user with this email address.' });
                break;
              default:
                setError('general', {
                  message:
                    'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
                });
                break;
            }
          } else {
            setError('general', {
              message:
                'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
            });
          }
          setLoading(false);
          setSaving(false);
        });
    },
    [isDirty, onSaved, sessionId, user.userData, setError]
  );

  const onReset = useCallback(
    e => {
      e.preventDefault();

      setValue('email', user.email);
      setValue('fname', user.userData.first_name);
      setValue('lname', user.userData.last_name);
      setValue('company', user.userData.company);
      setValue('tel', user.userData.phone);
    },
    [user, setValue]
  );

  const onChangeConsent = useCallback(
    value => {
      setLoading(true);
      request
        .post(
          config.adaptiveUrl + 'WebServices/generic/Privacy.asmx/' + (value ? 'Accept' : 'Revoke')
        )
        .set('gm_session_id', sessionId)
        .send({ category: config.accept_email_consent, version: 1 })
        .then(res => res.body)
        .then(() => {
          setConsentEmail(value);
          setLoading(false);
          onSaved();
        })
        .catch(err => {
          console.warn(err);
          setLoading(false);
        });
    },
    [sessionId, onSaved]
  );

  useEffect(() => {
    if (confirmDelete) {
      const tId = setTimeout(() => setConfirmDelete(false), 8000);
      return () => clearTimeout(tId);
    }
  }, [confirmDelete]);

  const onDeleteUser = useCallback(() => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }

    setLoading(true);
    request
      .delete(config.adaptiveUrl + 'api/profile/me')
      .set('Authorization', 'Bearer ' + sessionId)
      .set('X-Adaptive-variant', 'routeinfo')
      .send()
      .then(res => {
        setLoading(false);
        setSessionId('');
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }, [sessionId, confirmDelete]);

  return (
    <div className="profile-editor">
      <form onSubmit={handleSubmit(onSubmit)}>
        {loading && <LoadingMask />}
        <>
          <div className="form-group">
            <label htmlFor="fname">First name: * </label>
            <input
              {...register('fname', {
                required: 'This field is required.',
                pattern: {
                  value: XRegExp('^[\\pL\\pM- ]+$', 'u'),
                  message: 'This field cannot contain numbers.'
                },
                maxLength: {
                  value: 50,
                  message: 'Cannot be longer than 50 characters.'
                }
              })}
              type="text"
              name="fname"
              className={['form-control', errors && errors.fname ? 'error' : ''].join(' ')}
              placeholder="Enter your first name"
            />
            {errors && errors.fname && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.fname.message }}
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="lname">Last name: * </label>
            <input
              {...register('lname', {
                required: 'This field is required.',
                pattern: {
                  value: XRegExp('^[\\pL\\pM- ]+$', 'u'),
                  message: 'This field cannot contain numbers.'
                },
                maxLength: {
                  value: 50,
                  message: 'Cannot be longer than 50 characters.'
                }
              })}
              type="text"
              name="lname"
              className={['form-control', errors && errors.lname ? 'error' : ''].join(' ')}
              placeholder="Enter your last name"
            />
            {errors && errors.lname && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.lname.message }}
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="company">Company name: </label>
            <input
              {...register('company', {
                minLength: {
                  value: 3,
                  message: 'Must be longer than 3 characters.'
                },
                maxLength: {
                  value: 100,
                  message: 'Cannot be longer than 100 characters.'
                }
              })}
              type="text"
              name="company"
              className={['form-control', errors && errors.company ? 'error' : ''].join(' ')}
              placeholder="Enter your company name"
            />
            {errors && errors.company && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.company.message }}
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="tel">Phone: </label>
            <input
              {...register('tel', {
                pattern: {
                  value: /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g,
                  message: 'Please enter a valid phone number.'
                }
              })}
              type="tel"
              name="tel"
              className={['form-control', errors && errors.tel ? 'error' : ''].join(' ')}
              placeholder="Enter your phone number"
            />
            {errors && errors.tel && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.tel.message }}
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email">E-mail address: * </label>
            <input
              disabled
              {...register('email', {
                required: 'This field is required.',
                pattern: {
                  value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'Email you have entered is not valid.'
                },
                maxLength: {
                  value: 70,
                  message: 'Cannot be longer than 70 characters.'
                }
              })}
              type="email"
              name="email"
              className={['form-control', errors && errors.email ? 'error' : ''].join(' ')}
              placeholder="Enter your e-mail"
            />
            {errors && errors.email && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.email.message }}
              />
            )}
          </div>

          <div className="form-group privacy">
            <h3>Default settings</h3>
            <label htmlFor="accept_email">
              <input
                {...register('accept_email')}
                type="checkbox"
                name="accept_email"
                id="accept_email"
                checked={consentEmail || false}
                onChange={e => onChangeConsent(e.target.checked)}
              />
              <span>Receive update information on API from Routeinfo</span>
              {errors && errors.accept_email && (
                <small
                  className="form-control--error"
                  dangerouslySetInnerHTML={{ __html: errors.accept_email.message }}
                />
              )}
            </label>
          </div>
        </>

        {saving ? (
          <LoadingDots />
        ) : (
          <>
            <div className={'actions' + (isDirty ? '' : ' hide')}>
              <button type="reset" className="btn btn-secondary" onClick={onReset}>
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
            <div className="below">
              {user && (
                <button
                  className={['btn', confirmDelete ? 'btn-info' : 'btn-link'].join(' ')}
                  onClick={onDeleteUser}
                >
                  {confirmDelete
                    ? 'Press once more to confirm deleting user'
                    : 'Delete user account'}
                </button>
              )}
              <button className="btn-link" onClick={() => setSessionId('')}>
                Logout
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default ProfileEditor;
