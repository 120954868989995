import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from './PrivateRoute';
import ArticleView from '../views/ArticleView';
import ArticleDetailView from '../views/ArticleDetailView';
import PageView from '../views/PageView';
import CoreLayout from '../containers/CoreLayout';
import LoginView from '../views/LoginView';
import RegisterView from '../views/RegisterView';
import ProfileView from '../views/ProfileView';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';

const AppRouter = ({ children }) => {
  return (
    <Router>
      <CoreLayout>
        {children}
        <Routes>
          <Route path="/index" element={<Navigate to="/" />} />
          <Route exact path="/" element={<ArticleView />} />
          <Route path="/view/:sourceId/:articleId" element={<ArticleDetailView />} />
          <Route path="/register" element={<RegisterView />} />
          <Route path="/login" element={<LoginView />} />
          <Route
            path="/profile"
            element={
              <PrivateRoute redirectPath={'/login'}>
                <ProfileView />
              </PrivateRoute>
            }
          />
          <Route path="/:page" element={<PageView />} />
        </Routes>
      </CoreLayout>
    </Router>
  );
};

AppRouter.propTypes = {
  children: PropTypes.node
};

export default AppRouter;
