import { useState, useEffect } from "react";
import request from "superagent";
import config from "../config/kystverket";

function useCmsPage(uri) {
  const [page, setPage] = useState();

  useEffect(() => {
    request
      .post(config.adaptiveUrl + "WebServices/cms/Page.asmx/GetPage")
      .send({
        site: config.cmsSite,
        uri
      })
      .then(res => res.body.d)
      .then(res => {
        if (!res.success || !res.records.length) {
          setPage(false);
          console.warn(res);
          return;
        }

        setPage(res.records[0]);
      })
      .catch(err => {
        setPage(false);
        console.warn(err);
      });
  }, [uri]);

  return page;
}

export default useCmsPage;
