import React, { createContext, useMemo } from 'react';
import useDataSource from '../hooks/useDataSource';

const DataContext = createContext();

const DataContextProvider = ({ children }) => {
  const port = useDataSource({ type: 'port' });
  const portfacility = useDataSource({ type: 'portfacility' });
  const route = useDataSource({ type: 'route' });
  const quay = useDataSource({ type: 'quay' });
  const mtr = useDataSource({ type: 'mtr' });
  const cpr = useDataSource({ type: 'cpr' });
  const svalbard = useDataSource({ type: 'svalbard' });

  const loading = useMemo(() => {
    return !(
      !port.loading &&
      port.items &&
      !portfacility.loading &&
      portfacility.items &&
      !route.loading &&
      route.items &&
      !quay.loading &&
      quay.items &&
      !mtr.loading &&
      mtr.items &&
      !cpr.loading &&
      cpr.items &&
      !svalbard.loading &&
      svalbard.items
    );
  }, [port, portfacility, route, quay, mtr, cpr, svalbard]);

  const items = useMemo(() => {
    if (!loading) {
      return [
        ...port.items,
        ...portfacility.items,
        ...route.items,
        ...quay.items,
        ...mtr.items,
        ...cpr.items,
        ...svalbard.items
      ];
    }
    return [];
  }, [port, portfacility, route, quay, mtr, cpr, loading, svalbard]);

  return (
    <DataContext.Provider
      value={{
        items,
        loading,
        port: port.items,
        portfacility: portfacility.items,
        route: route.items,
        quay: quay.items,
        mtr: mtr.items,
        cpr: cpr.items,
        svalbard: svalbard.items
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

const DataContextConsumer = DataContext.Consumer;

export { DataContext, DataContextProvider, DataContextConsumer };
