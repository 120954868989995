import React from 'react';
import Icon from '../Icon';
import './Loading.scss';

export default () => (
  <div className="loading">
    <div>
      <Icon name="load" />
      <span>Loading...</span>
    </div>
  </div>
);
