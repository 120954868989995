import { useEffect } from 'react';
import PropTypes from 'prop-types';

function Title({ title }) {
  useEffect(() => {
    const oldTitle = document.title;
    if (title) {
      document.title = title;
    }

    return () => {
      document.title = oldTitle;
    };
  }, [title]);

  return null;
}

Title.propTypes = {
  title: PropTypes.string.isRequired
};

export default Title;
