const getPath = location => {
  return location?.pathname?.length > 1 ? location?.pathname?.slice(1) : 'index';
};

const fixLinks = navigate => {
  let pageContent = document.getElementsByClassName('route-info--cms')[0];
  if (pageContent) {
    let links = pageContent.getElementsByTagName('a');
    [].forEach.call(links, link => {
      if (
        ['localhost', 'routeinfo.avinet', 'routeinfo'].some(s => window.location.host.includes(s))
      ) {
        // for testing purposes, whole scope should be removed after testing in production
        if (link.host !== 'routeinfo.no' || /\..{3,4}$/.test(link.href) || link.target) {
          return;
        }
        link.onclick = e => {
          let nl = link.pathname;
          e.preventDefault();
          if (link.pathname.indexOf('/') !== 0) {
            nl += '/' + nl;
          }
          navigate(nl);
        };
      }
    });
  }
};

export { getPath, fixLinks };
