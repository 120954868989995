import React from 'react';
import LoadingDots from './LoadingDots';
import './LoadingMask.scss';

const LoadingMask = () => (
  <div className="loading-mask">
    <LoadingDots />
  </div>
);

export default LoadingMask;
