export const SourceIndexes = {
  ROUTE: 'route',
  LOCATION: 'location',
  QUAY: 'quay',
  PORT: 'port',
  PORT_FACILITY: 'portfacility',
  CPR: 'cpr',
  MTR: 'mtr',
  VTS: 'vts',
  SVALBARD: 'svalbard'
};

export function titlePrefix(type) {
  switch (type) {
    case SourceIndexes.ROUTE:
      return 'Route: ';
    case SourceIndexes.PORT:
      return 'Port: ';
    case SourceIndexes.PORT_FACILITY:
      return 'Port Facility: ';
    case SourceIndexes.QUAY:
      return 'Quay: ';
    case SourceIndexes.MTR:
      return 'Local Regulations (VTS): ';
    case SourceIndexes.CPR:
      return 'Local Regulations for Use of PEC: ';
    case SourceIndexes.SVALBARD:
      return 'Svalbard, Regulations and Information Services: ';
    default:
      return '';
  }
}
