import React, { useState } from 'react';
import './PopupBox.scss';

const PopupBox = ({ checkBoxChecked, onClose, children, title }) => {
  const [checked] = useState(checkBoxChecked);

  return (
    <div className="popup-box--container">
      <div className="popup-box--header">
        {title && <span>{title}</span>}
        <button onClick={() => onClose(checked)} className="popup-box--close-btn">
          <span className="glyphicon glyphicon-remove" />
        </button>
      </div>
      <div className="popup-box--content">{children}</div>
    </div>
  );
};

export default PopupBox;
