import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import './Collapsible.scss';

export default class Collapsible extends React.Component {
  static propTypes = {
    collapsed: PropTypes.bool,
    children: PropTypes.node,
    header: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.collapsed
    };
  }

  onClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const { header, children } = this.props;
    return (
      <div className="collapsible--root">
        <div className="collapsible--header" onClick={this.onClick}>
          {header}
          <Icon name={this.state.collapsed ? 'chevrondown' : 'chevronup'} />
        </div>
        <div className={['collapsible--content', this.state.collapsed ? '' : 'open'].join(' ')}>
          {children}
        </div>
      </div>
    );
  }
}
