import { useState, useEffect, useCallback } from 'react';
import pubsub from '../utils/pubsub';
import { setItem, getItem } from '../utils/syncedLocalStorage';

/**
 * Gets a syncronised value from localStorage.
 *
 * Gets syncronised when value is updated via syncedLocalStorage utility, or
 * when value changes in a different document context (window/tab).
 *
 * @param {string} key Key to watch/update
 */
export default function useSyncedLocalStorage(key) {
  const [value, setCachedValue] = useState(() => getItem(key));

  // Adds listener to pubsub hub for local storage modifications performed
  // in the current window/tab.
  useEffect(() => {
    return pubsub.subscribe('localStorage/' + key, message => setCachedValue(message));
  }, [key]);

  // Adds listener to storage events to get notified if local storage
  // item changes in a different window/tab.
  useEffect(() => {
    const onStorage = e => {
      if (e.key === key) {
        setCachedValue(e.newValue);
      }
    };

    window.addEventListener('storage', onStorage);

    return () => window.removeEventListener('storage', onStorage);
  }, [key]);

  // Update value when key changes
  useEffect(() => {
    setCachedValue(getItem(key));
  }, [key]);

  // Callback to set value in both state and local storage
  const setValue = useCallback(
    value => {
      setItem(key, value);
      setCachedValue(value);
    },
    [key]
  );

  return [value, setValue];
}
