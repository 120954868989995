import React, { useState } from 'react';
import { Nav, Link, useNav } from '@avinet/react-adaptive-cms';
import Search from '../components/search/Search';
import Icon from '../components/Icon';
import logo from '../static/RGB-logo-012022-m tekst.svg';
import './NavBar.scss';

const NavBar = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const nav = useNav();

  return (
    <div className="nav-bar--container">
      <Link to="/" className="nav-bar--header-image">
        <img src={logo} alt="" />
      </Link>
      <Search className={searchOpen ? 'open' : ''} />
      <button
        className={['nav-bar--search-button', searchOpen ? 'open' : ''].join(' ')}
        onClick={() => setSearchOpen(!searchOpen)}
      >
        <Icon name="search" />
      </button>
      <div className={['nav-bar--menu', navOpen ? ' nav-bar--menu-active' : ''].join(' ')}>
        <input
          type="checkbox"
          className="nav-bar--hamburger"
          onClick={() => setNavOpen(!navOpen)}
        />
        <span />
        <span />
        <span />
        <Nav nav={nav} onNavClick={() => setNavOpen(false)} />
      </div>
    </div>
  );
};

export default NavBar;
