import { useMemo, useContext } from 'react';
import { DataContext } from '../contexts/DataContext';
import { getDistance } from 'ol/sphere';

const useItems = ({ mapExtent, mapCenter }) => {
  const { items, loading } = useContext(DataContext);

  const filteredItems = useMemo(() => {
    if (loading || !items || !mapExtent || !mapCenter) return;

    return items
      .filter(i => i.olFeature.getGeometry().intersectsExtent(mapExtent))
      .map(i => {
        i.calcDist = getDistance(mapCenter, i.olFeature.getGeometry().getClosestPoint(mapCenter));
        return i;
      })
      .sort((a, b) => b.calcDist - a.calcDist);
  }, [items, mapExtent, mapCenter, loading]);

  return { loading, items: filteredItems };
};

export default useItems;
