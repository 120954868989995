import { useState, useEffect, useCallback, useRef } from 'react';
import request from 'superagent';
import config from '../config/kystverket';
import { processRecords } from '../utils/adaptive';

const readAnyUrl = config.adaptiveUrl + 'WebServices/client/DataView.asmx/ReadAny';

function load(
  {
    type,
    sessionId,
    filter,
    start,
    limit,
    onlyOwned,
    fkColumn,
    fkColumnId,
    sortByGeomColumn,
    sortByGeomGeometry
  },
  callback
) {
  if (type in config.themes) {
    const req = request
      .post(readAnyUrl + '?type=' + type) // Query string is not used, this is just to identify requests in network tab
      .set({
        'X-Adaptive-SRID': config.srid.toString()
      })
      .send({
        request: {
          theme_uuid: config.themes[type].theme_uuid,
          columns: config.themes[type].columns,
          only_owned: onlyOwned || false,
          fk_column: fkColumn,
          fk_column_id: fkColumnId,
          filter: filter || {},
          start: start || 0,
          limit: limit || 0,
          sortByGeomColumn,
          sortByGeomGeometry
        }
      });

    req
      .then(res => res.body)
      .then(res => {
        if (!res.d.success) {
          console.error('Request response unsuccessful', res.d);
          callback(null, 0);
          return;
        }
        callback(processRecords(res.d.records, type), res.d.total);
      })
      .catch(err => {
        if (err.code && err.code === 'ABORTED') return;
        callback(null, 0);
        console.warn('Request failed', err);
      });

    return () => req.abort();
  }
}

function useParamWatch(ref, value, pause, reload) {
  useEffect(() => {
    if (pause) return;
    if (ref.current !== value) {
      ref.current = value;
      reload();
    }
  }, [reload, ref, value, pause]);
}

/**
 * Loads an array of data from a data source specified by type.
 *
 * @param {Object} options Data source options
 * @param {string} options.type Type of data to load
 * @param {string|undefined} options.fkColumn The foreign key column (parent object) to filter on
 * @param {Number|undefined} options.fkColumnId The ID of the parent object to filter on
 * @param {Object} options.filter Adaptive filter structure
 * @param {Number} options.start Item offset
 * @param {Number} options.limit Max items to load
 * @param {string} options.sortByGeomColumn
 * @param {string} options.sortByGeomGeometry
 * @returns {{ items: Array, total: Number, loading: Boolean, reload: ({ reset: Boolean }) => void}}
 */
export default function useDataSource({
  pause = false,
  type,
  onlyOwned,
  fkColumn,
  fkColumnId,
  filter,
  start = 0,
  limit = 0,
  sortByGeomColumn,
  sortByGeomGeometry
}) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState();
  const [total, setTotal] = useState(0);

  const filterRef = useRef(filter);
  const startRef = useRef(start);

  const requestRef = useRef();

  const reload = useCallback(
    ({ reset = false } = {}) => {
      if (reset) {
        setItems(undefined);
        setTotal(0);
      }
      setLoading(true);

      if (requestRef.current) requestRef.current();
      requestRef.current = load(
        {
          type,
          onlyOwned,
          fkColumn,
          fkColumnId,
          start: startRef.current,
          limit,
          filter: filterRef.current,
          sortByGeomColumn,
          sortByGeomGeometry
        },
        (data, total) => {
          setItems(data);
          setTotal(total);
          setLoading(false);
        }
      );
    },
    [
      type,
      onlyOwned,
      fkColumn,
      fkColumnId,
      startRef,
      limit,
      filterRef,
      sortByGeomColumn,
      sortByGeomGeometry,
      requestRef
    ]
  );

  useParamWatch(filterRef, filter, pause, reload);
  useParamWatch(startRef, start, pause, reload);

  useEffect(() => {
    if (pause) return;
    reload({ reset: true });

    return () => requestRef.current();
  }, [reload, pause, requestRef]);

  return { items, total, loading, reload };
}
