// import 'babel-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './containers/App';
import config from './config/kystverket';
import { CmsConfig } from '@avinet/react-adaptive-cms';
import './index.scss';

CmsConfig.init(config.adaptiveUrl, config.cmsSite);

const container = document.getElementById('root');

const root = createRoot(container);

root.render(<App />);
