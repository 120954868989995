import React, { useCallback, useState } from 'react';
import PopupBox from '../PopupBox';
import { useNavigate } from 'react-router-dom';
import { VectorSource } from '@avinet/react-openlayers';
import LoadingDots from '../loading/LoadingDots';
import { featureStyle } from '../../utils/featureStyle';
import { SourceIndexes, titlePrefix } from '../../config/SourceConfig';
import config from '../../config/kystverket';
import './FeatureSelect.scss';

const FeatureSelect = ({ items, onPopupClose, loading }) => {
  const [feature, setFeature] = useState();
  const navigate = useNavigate();

  const onArticleOpen = useCallback(
    item => {
      navigate(config.themes[item.type].url + item.uid.split('.')[2]);
      window.scroll(0, 0);

      onPopupClose();
    },
    [navigate, onPopupClose]
  );

  return (
    <>
      <PopupBox
        title={
          loading ? (
            <h3>
              Searching <LoadingDots />
            </h3>
          ) : (
            <h3>Hits ({items && items.length ? items.length : 0})</h3>
          )
        }
        onClose={() => onPopupClose()}
      >
        {!loading && (
          <ul className="feature-select--list-container">
            {items &&
              items.length > 0 &&
              items.map((item, idx) => (
                <li
                  className={[
                    'feature-select--list-item',
                    item.type,
                    item.type === SourceIndexes.CPR
                      ? item.c1_dp === 0
                        ? 'red'
                        : item.c1_dp === 1 && item.sok_kl1 !== 0
                        ? 'blue'
                        : ''
                      : ''
                  ].join(' ')}
                  onClick={() => onArticleOpen(item)}
                  onMouseOver={() => {
                    setFeature(item.olFeature);
                  }}
                  onMouseOut={() => {
                    setFeature(undefined);
                  }}
                  key={idx}
                >
                  <h4>
                    {titlePrefix(config.layerIds[item.layerId])} {item.title}
                  </h4>
                </li>
              ))}
          </ul>
        )}
      </PopupBox>
      {feature && !loading && (
        <VectorSource
          layerName="hover"
          features={[feature]}
          selectable={false}
          style={featureStyle}
        />
      )}
    </>
  );
};

export default FeatureSelect;
