import config from '../config/kystverket';
import { SourceIndexes } from '../config/SourceConfig';
import { olUtils } from "@avinet/react-openlayers";

export function processRecord(record, type) {
  const r = {};
  Object.keys(record).forEach(key => {
    if (/^\/Date\(.*\)\/$/.test(record[key])) {
      r[key] = new Date(parseInt(record[key].substring(6)));
    } else if (key === 'geom_wkt') {
      r.olFeature = olUtils.createFeatureFromWkt(record[key], { ...record, type });
    } else {
      r[key] = record[key];
    }
  });

  if (type === SourceIndexes.ROUTE) {
    r.ssn_ports = r.ssn_ports
      ? r.ssn_ports
          .split(',')
          .filter(f => f !== null)
          .map(m => parseInt(m.trim()))
      : [];

    r.ssn_port_facilities = r.ssn_port_facilities
      ? r.ssn_port_facilities
          .split(',')
          .filter(f => f !== null)
          .map(m => parseInt(m.trim()))
      : [];

    r.ssn_quays = r.ssn_quays
      ? r.ssn_quays
          .split(',')
          .filter(f => f !== null)
          .map(m => parseInt(m.trim()))
      : [];
  }

  r.type = type;
  return r;
}

export function processRecords(records, type) {
  return records.map(r => processRecord(r, type));
}

/**
 * Convert a media uuid to the appropriate adaptive URL.
 *
 * @param {string} media Media uuid
 * @param {string} size full, large, medium or small
 * @param {string} sessionId if private image, this must be set to a valid session id
 * @param {bool} download Set to true to get a download link (sets Content-Disposition to trigger a file download)
 */
export function imageUrl(media, { size = 'large', sessionId = undefined, download = false } = {}) {
  if (!media) return undefined;

  let url = config.adaptiveUrl + 'WebServices/generic/Media.asmx/Download?uuid=' + media;

  if (size !== 'full') {
    url += '&thumbnail_size=' + (size || 'large');
  }

  if (sessionId) {
    url += '&gm_session_id=' + sessionId;
  }

  if (download) {
    url += '&download=1';
  }

  return url;
}
