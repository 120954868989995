import pubsub from './pubsub';

let lStorage;
try {
  lStorage = localStorage;
  lStorage.getItem('test');
} catch (ex) {
  // This can happen when user has blocked all cookies. Simulate localStorage by persisting data during
  // current session only. Reloading page will clear data.
  const dataCache = {};
  lStorage = {
    getItem: key => dataCache[key],
    setItem: (key, value) => {
      dataCache[key] = value;
    },
    isSimulated: true
  };
}

export const isLocalStorageAvailable = () => (lStorage.isSimulated ? false : true);

export const getItem = key => lStorage.getItem(key);

export const setItem = (key, value) => {
  lStorage.setItem(key, value);
  pubsub.publish('localStorage/' + key, value);
};

export default { getItem, setItem };
