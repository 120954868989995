import React from 'react';
import './LoadingDots.scss';

const LoadingDots = () => (
  <span className="loading-dots">
    <span />
    <span />
    <span />
    <span />
  </span>
);

export default LoadingDots;
