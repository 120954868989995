import { useContext, useEffect } from 'react';
import { MapContext, olUtils } from "@avinet/react-openlayers";
import Point from 'ol/geom/Point';
import { scaleFromCenter } from 'ol/extent';

/**
 * Makes the main map zoom to the given point and zoom level.
 *
 * @param {String} geom_wkt WKT for POINT to zoom to (in map projection)
 * @param {Number} zoom (Max) zoom level to zoom to
 */
export function useZoomToGeomWkt(geom_wkt, zoom) {
  const { setCenterAndZoom, fitViewAndZoom } = useContext(MapContext);

  useEffect(() => {
    if (!geom_wkt) return;
    const f = olUtils.createFeatureFromWkt(geom_wkt);
    if (!f) return;
    if (f.getGeometry() instanceof Point) {
      setCenterAndZoom(f.getGeometry().getCoordinates(), zoom);
    } else {
      fitViewAndZoom(f.getGeometry(), zoom);
    }
  }, [geom_wkt, zoom, fitViewAndZoom, setCenterAndZoom]);
}

/**
 * Makes the main map zoom to the given features extent.
 *
 * @param {String} features Features to zoom to
 * @param {Number} zoom (Max) zoom level to zoom to
 * @param {Number} scale Scale factor of extent before fit
 */
export function useZoomToFeatures(features, zoom, scale = 1) {
  const { fitViewAndZoom } = useContext(MapContext);

  useEffect(() => {
    if (!features || !features.length) return;
    const extent = olUtils.getExtentFromFeatures(features);
    if (!extent || !extent.length) return;
    scaleFromCenter(extent, scale);
    fitViewAndZoom(extent, zoom);
  }, [features, zoom, scale, fitViewAndZoom]);
}
