const pubsub = (() => {
  const _listeners = {};
  return {
    subscribe: (topic, listener) => {
      if (!_listeners.hasOwnProperty(topic)) _listeners[topic] = [];

      const index = _listeners[topic].push(listener) - 1;

      return () => delete _listeners[topic][index];
    },
    publish: (topic, message) => {
      if (!_listeners.hasOwnProperty(topic)) return;

      _listeners[topic].forEach(l => l && l(message));
    }
  };
})();

export default pubsub;
