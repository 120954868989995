import { useMemo, useContext } from 'react';
import { DataContext } from '../contexts/DataContext';
import config from '../config/kystverket';

const useLocations = ({ ids, type }) => {
  const { [type]: items, loading } = useContext(DataContext);

  const idKey = useMemo(() => config.themes[type].id, [type]);

  const filteredItems = useMemo(() => {
    if (!items) return;

    return items.filter(i => ids.indexOf(i[idKey]) > -1);
  }, [items, ids, idKey]);

  return { loading, items: filteredItems };
};

export default useLocations;
