import { useState, createRef, useEffect } from 'react';

export default function usePopup() {
  const [visible, setVisible] = useState(false);
  const popupRef = createRef();
  useEffect(() => {
    if (!visible) return;
    const listener = e => {
      if (visible && popupRef.current && !popupRef.current.contains(e.target)) {
        setTimeout(() => setVisible(false), 200);
      }
    };
    setTimeout(() => {
      window.addEventListener('mousedown', listener);
      window.addEventListener('touchstart', listener);
    }, 0);
    return () => {
      window.removeEventListener('mousedown', listener);
      window.removeEventListener('touchstart', listener);
    };
  }, [popupRef, visible]);

  return [popupRef, visible, setVisible];
}
