import React, { useState, useCallback } from 'react';
import FeatureSelect from './FeatureSelect';
import { provideMapState, ensureMap, WmsLayer, MapEvent } from '@avinet/react-openlayers';
import config from '../../config/kystverket';
import useSearch from '../../hooks/useSearch';

const Layers = props => {
  const [showPopup, setShowPopup] = useState(false);
  const [coordinates, setCoordinates] = useState();
  const [searchRadius, setSearchRadius] = useState();

  const onMapSingleClick = useCallback(
    e => {
      const { mapZoom, getResolution } = props;
      const resolution = getResolution(mapZoom);
      props.setCenter(e.coordinate, props.mapZoom);

      let searchRadius = 11 * resolution;
      if (searchRadius < 12) searchRadius = 12;

      setCoordinates(e.coordinate);
      setSearchRadius(searchRadius);
      setShowPopup(true);
    },
    [props]
  );

  const { items, loading } = useSearch({ coordinates: coordinates, searchRadius: searchRadius });

  const onPopupClose = useCallback(() => {
    setShowPopup(false);
  }, []);

  return (
    <>
      <MapEvent onMapSingleClick={e => onMapSingleClick(e)} />

      {showPopup && <FeatureSelect items={items} onPopupClose={onPopupClose} loading={loading} />}

      <WmsLayer
        id="Port"
        name="Port"
        uri={config.a3WmsUrl}
        layerName={config.layerIds.port}
        version="1.1.1"
        singleTile
        key="port"
      />
      <WmsLayer
        id="Port facilities"
        name="Port facilities"
        uri={config.a3WmsUrl}
        layerName={config.layerIds.port_facilities}
        version="1.1.1"
        singleTile
        key="port_facilities"
      />
      <WmsLayer
        id="Quay"
        name="Quay"
        uri={config.a3WmsUrl}
        layerName={config.layerIds.quay}
        version="1.1.1"
        singleTile
        key="quay"
      />
      <WmsLayer
        id="Routes"
        name="Routes"
        uri={config.a3WmsUrl}
        layerName={config.layerIds.routes}
        version="1.1.1"
        singleTile
        key="routes"
      />
    </>
  );
};

export default ensureMap(provideMapState(Layers));
