import React, { useState, useCallback, useContext } from 'react';
import Title from '../components/Title';
import LoadingMask from '../components/loading/LoadingMask';
import XRegExp from 'xregexp';
import request from 'superagent';
import { registerConsent } from '../utils/auth';
import { useForm } from 'react-hook-form';
import { setSessionId } from '../utils/sesionId';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { redirect, useLocation } from 'react-router-dom';

import config from '../config/kystverket';

import './LoginView.scss';

export default function RegisterView() {
  const { state: locationState } = useLocation();
  const { from } = locationState || {};
  const [loading, setLoading] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const { register, handleSubmit, errors, setError } = useForm({
    mode: 'onChange'
  });

  const onSubmit = useCallback(
    data => {
      setLoading(true);
      const req = request
        .post(config.adaptiveUrl + 'api/Auth/Register') // <== use for prod
        // .post('http://localhost/a_a3/api/Auth/Register') // <== use for dev
        .set('X-Adaptive-variant', 'routeinfo')
        .send({
          email: data.email,
          password: data.password,
          userData: {
            first_name: data.fname,
            last_name: data.lname,
            company: data.company
          }
        });
      req
        .then(res => res.body)
        .then(res => {
          if (res.success && res.session_id) {
            const sessionId = res.session_id;

            for (const key in data) {
              key.includes('_consent') && !!data[key] && registerConsent(sessionId, config[key]);
            }

            setTimeout(() => {
              setSessionId(sessionId);
              setShouldRedirect(true);
            }, 1000);
          } else {
            setError('general', {
              message:
                'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
            });
            setLoading(false);
          }
        })
        .catch(err => {
          if (err.response && err.response.statusCode) {
            switch (err.response.statusCode) {
              case 400:
                setError('email', { message: 'The email address is not valid' });
                break;

              case 409:
                setError('email', {
                  message:
                    'This email is already registered. Login or visit account recovery page to get access to this account.'
                });
                break;

              default:
                setError('general', {
                  message:
                    'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
                });
                break;
            }
          } else {
            setError('general', {
              message:
                'An error has occurred in our service. We apologize for this. Please try again later, or contact support if the problem persists.'
            });
          }
          setLoading(false);
        });
    },
    [setError]
  );

  if (useContext(UserContext) || shouldRedirect) {
    redirect(from || '/profile');
  }

  return (
    <div className="userForm-container">
      <div className="userForm-content" id="formView-scrollPane">
        {loading && <LoadingMask />}
        <Title title="Register - Kystverket" />
        <h2>Register new user</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="fname">First name: * </label>
            <input
              {...register('fname', {
                required: 'This field is required.',
                pattern: {
                  value: XRegExp('^[\\pL\\pM- ]+$', 'u'),
                  message: 'This field cannot contain numbers.'
                },
                maxLength: {
                  value: 50,
                  message: 'Cannot be longer than 50 characters.'
                }
              })}
              type="text"
              name="fname"
              className={['form-control', errors && errors.fname ? 'error' : ''].join(' ')}
              placeholder="Enter your first name"
            />
            {errors && errors.fname && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.fname.message }}
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="lname">Last name: * </label>
            <input
              {...register('lname', {
                required: 'This field is required.',
                pattern: {
                  value: XRegExp('^[\\pL\\pM- ]+$', 'u'),
                  message: 'This field cannot contain numbers.'
                },
                maxLength: {
                  value: 50,
                  message: 'Cannot be longer than 50 characters.'
                }
              })}
              type="text"
              name="lname"
              className={['form-control', errors && errors.lname ? 'error' : ''].join(' ')}
              placeholder="Enter your last name"
            />
            {errors && errors.lname && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.lname.message }}
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="company">Company name: </label>
            <input
              {...register('company', {
                minLength: {
                  value: 3,
                  message: 'Must be longer than 3 characters.'
                },
                maxLength: {
                  value: 100,
                  message: 'Cannot be longer than 100 characters.'
                }
              })}
              type="text"
              name="company"
              className={['form-control', errors && errors.company ? 'error' : ''].join(' ')}
              placeholder="Enter your company name"
            />
            {errors && errors.company && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.company.message }}
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="tel">Phone: </label>
            <input
              {...register('tel', {
                pattern: {
                  value: /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g,
                  message: 'Please enter a valid phone number.'
                }
              })}
              type="tel"
              name="tel"
              className={['form-control', errors && errors.tel ? 'error' : ''].join(' ')}
              placeholder="Enter your phone number"
            />
            {errors && errors.tel && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.tel.message }}
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email">E-mail address: * </label>
            <input
              {...register('email', {
                required: 'This field is required.',
                pattern: {
                  value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'Email you have entered is not valid.'
                },
                maxLength: {
                  value: 70,
                  message: 'Cannot be longer than 70 characters.'
                }
              })}
              type="email"
              name="email"
              className={['form-control', errors && errors.email ? 'error' : ''].join(' ')}
              placeholder="Enter your e-mail"
            />
            {errors && errors.email && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.email.message }}
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="password">Password: * </label>
            <input
              {...register('password', {
                required: 'This field is required.',
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{4,}$/gm,
                  message:
                    '<div>Password must contain:  <ul><li>at least one upper case letter,</li> <li> at least one lower case letter,</li> <li>at least one digit,</li><li>at least one special character,</li><li>and must be longer than 4 characters</li></ul><div>'
                }, // <= enable patern validation before goes to production
                minLength: {
                  value: 4,
                  message: 'Must be longer than 4 characters.'
                }
              })}
              type="password"
              name="password"
              className={['form-control', errors && errors.password ? 'error' : ''].join(' ')}
              placeholder="Enter your password"
            />
            {errors && errors.password && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.password.message }}
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm password: * </label>
            <input
              {...register('confirmPassword', {
                required: 'This field is required.',
                minLength: { value: 4, message: 'Must be longer than 4 characters.' }
              })}
              type="password"
              name="confirmPassword"
              className={['form-control', errors && errors.confirmPassword ? 'error' : ''].join(
                ' '
              )}
              placeholder="Please confirm your password"
            />
            {errors && errors.confirmPassword && (
              <small
                className="form-control--error"
                dangerouslySetInnerHTML={{ __html: errors.confirmPassword.message }}
              />
            )}
          </div>

          <div className="form-group privacy">
            <h3>Consent</h3>
            <label htmlFor="terms_consent">
              <input
                {...register('terms_consent', {
                  required: 'This field is required.',
                  value: true
                })}
                type="checkbox"
                name="terms_consent"
                id="terms_consent"
              />
              <span>
                I accept the{' '}
                <Link to="/terms" target="__blank">
                  Terms and Conditions
                </Link>
              </span>
              {errors && errors.terms_consent && (
                <small
                  className="form-control--error"
                  dangerouslySetInnerHTML={{ __html: errors.terms_consent.message }}
                />
              )}
            </label>

            <label htmlFor="privacy_consent">
              <input
                {...register('privacy_consent', { required: 'This field is required.' })}
                type="checkbox"
                name="privacy_consent"
                id="privacy_consent"
              />
              <span>
                I accept the{' '}
                <a
                  href="https://kystverket.no/en/About-Kystverket/privacy-statement/"
                  target="__blank"
                >
                  Privacy Statement
                </a>{' '}
                issued by the Norwegian Coastal Administration
              </span>
              {errors && errors.privacy_consent && (
                <small
                  className="form-control--error"
                  dangerouslySetInnerHTML={{ __html: errors.privacy_consent.message }}
                />
              )}
            </label>

            <label htmlFor="accept_email_consent">
              <input
                {...register('accept_email_consent')}
                type="checkbox"
                name="accept_email_consent"
                id="accept_email_consent"
              />
              <span>Receive update information on API from Routeinfo</span>
              {errors && errors.accept_email_consent && (
                <small
                  className="form-control--error"
                  dangerouslySetInnerHTML={{ __html: errors.accept_email_consent.message }}
                />
              )}
            </label>
          </div>

          {errors && errors.general && (
            <small
              className="form-control--error"
              dangerouslySetInnerHTML={{
                __html: `<div style="margin-bottom: 15px"><p>${errors.general.message}<p><div>`
              }}
            />
          )}

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
        <Link className="btn-link  already-registered" to="/login">
          Already registered? Login
        </Link>
      </div>
    </div>
  );
}
