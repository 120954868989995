import React, { useState } from 'react';
import { VectorSource } from "@avinet/react-openlayers";
import { featureStyle } from '../../utils/featureStyle';

const Result = ({ results, onSelect }) => {
  const [selected, setSelected] = useState('');
  const [feature, setFeature] = useState();

  const layerGroupNames = [...new Set(results.map(result => result.layerName))];
  return (
    <div className="search--searchSources" tabIndex={0}>
      {layerGroupNames.map((layer, i) => {
        const resultList = results.filter(result => result.layerName === layer);
        const numResults = resultList.length;

        return (
          <div className="searchSource--container" key={i} tabIndex={0}>
            <h2>
              {layer} ({numResults})
            </h2>
            {numResults > 5 && (
              <button
                className="pull-right btn btn-link"
                title={'showAllResults'}
                onClick={() => (layer === selected ? setSelected(undefined) : setSelected(layer))}
              >
                {selected === layer ? 'Back' : 'Show all results'}
              </button>
            )}
            <ul className={numResults === 0 ? 'searchSource--noResults' : ''}>
              {resultList.slice(0, selected === layer ? numResults : 5).map(result => {
                return (
                  <li
                    key={result.uid}
                    className="clearfix"
                    onClick={() => onSelect(result)}
                    onMouseOver={() => setFeature(result.olFeature)}
                    onMouseOut={() => setFeature(undefined)}
                  >
                    <h3>{result.title}</h3>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
      {feature && (
        <VectorSource
          layerName="hover"
          features={[feature]}
          selectable={false}
          style={featureStyle}
        />
      )}
    </div>
  );
};

export default Result;
