import { useState, useEffect } from 'react';
import { olUtils } from '@avinet/react-openlayers';
import request from 'superagent';

import config from '../config/kystverket';

export default function useSearch({ query, coordinates, searchRadius }) {
  const [items, setItems] = useState();
  const [loading, setLoading] = useState(false);
  const [bufferedQuery, setBufferedQuery] = useState();

  useEffect(() => {
    let tId;
    if (query && query.length < 3) {
      return;
    }
    tId = setTimeout(() => {
      setBufferedQuery(query);
    }, 500);

    return () => clearTimeout(tId);
  }, [query]);

  useEffect(() => {
    setLoading(true);
    setItems(null);

    const req = request
      .post(`${config.adaptiveUrl}/WebServices/search/SearchProxy.asmx/Search`)
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('gm_lang_code', 'en')
      .send({
        request: {
          bbox: undefined, //{ bottom: extent[0], left: extent[1], right: extent[2], top: extent[3] },
          layers: Object.keys(config.layerIds)
            .filter(v => !isNaN(v))
            .join(', '),
          limit: 100,
          q: bufferedQuery ? `${bufferedQuery}*` : null,
          s: `${coordinates},${searchRadius}`,
          srsid: config.mapProjCode,
          st: !bufferedQuery ? 'circle' : null,
          start: 0
        }
      });
    req
      .then(res => res.body.d)
      .then(res => {
        if (!res || !res.success) {
          setLoading(false);
          setItems(null);
          return;
        }
        const records = res.records.map(record => {
          record.type = config.layerIds[record.layerId];
          record.olFeature = olUtils.createFeatureFromWkt(record['fullGeom'], {
            ...record
          });
          return {
            ...record,
            ...JSON.parse(record.values, (key, value) => {
              if (key === 'c1_dp' || key === 'sok_kl1') {
                return parseInt(value);
              }
              return value;
            })
          };
        });

        setItems(() => records);
        setLoading(() => false);
      })
      .catch(err => {
        if (err.code && err.code === 'ABORTED') return;

        setItems(null);
        setLoading(false);
        console.warn('Request failed', err);
      });

    return () => req.abort();
  }, [setItems, setLoading, coordinates, searchRadius, bufferedQuery]);

  return { items, loading };
}
