import React, { useState, useMemo, useCallback } from 'react';
import JSZip from 'jszip';
import superagent from 'superagent';
import Loading from '../loading/Loading';
import Collapsible from '../Collapsible';
import useRoutes from '../../hooks/useRoutes';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { buildRouteName } from '../../utils/utils';

import config from '../../config/kystverket';

function getRtzUrl(routenumber) {
  return config.adaptiveUrl + '/api/kystverket/routeinfo/v2/routes/' + routenumber + '/download';
}

const LocationsAfter = ({ type, locationid }) => {
  const [, setDownloading] = useState(false);
  const [, setDownloadError] = useState(null);

  const navigate = useNavigate();

  const { loading, items } = useRoutes({ type, locationid });

  const zipEntries = useCallback((zip, rtz, entries, index = 0) => {
    const entry = entries[index];

    if (index === 0) {
      setDownloading(true);
      setDownloadError(null);
    }

    if (index === entries.length) {
      zip.generateAsync({ type: 'blob' }).then(blob => {
        saveAs(blob, rtz + '_routes.zip');
        setDownloading(false);
      });
      return;
    }

    superagent
      .get(entry.url)
      .responseType('blob')
      .end((err, res) => {
        if (err) {
          setDownloading(false);
          setDownloadError(err);
          return;
        }
        zip.file(entry.title, res.body);
        zipEntries(zip, rtz, entries, index + 1);
      });
  }, []);

  const onDownloadRoutesRtz = useCallback(
    rtz => {
      const entries = [];
      const cbs = document.getElementsByClassName('routeRtzDownload');
      for (var i = 0; i < cbs.length; ++i) {
        const cb = cbs[i];
        const routeId = cb.attributes['data-' + rtz].value;
        const title = cb.attributes['data-title'].value;
        if (cb.checked && routeId) {
          entries.push({
            url: getRtzUrl(routeId),
            title: title
          });
        }
      }

      if (entries.length) {
        const zip = new JSZip();
        zipEntries(zip, rtz, entries);
      }
    },
    [zipEntries]
  );

  const routes = useMemo(() => {
    const gr = {
      in: [],
      out: [],
      between: [],
      opensea: []
    };

    if (!items) return;

    items.forEach(r => {
      var dir = parseInt(r.routeinfo_vesselvoyage.substring(4, 5));
      switch (dir) {
        case 1:
          gr.in.push(r);
          break;
        case 2:
          gr.out.push(r);
          break;
        case 3:
          gr.between.push(r);
          break;
        case 4:
        case 5:
          gr.opensea.push(r);
          break;
        default:
          break;
      }
    });

    return gr;
  }, [items]);

  const renderRoute = useCallback(
    route => {
      const {
        routequalitystatus,
        routeinfo_routename,
        extension_routenumber,
        routeinfo_validityperiodstart
      } = route;
      const routename = buildRouteName({
        routeQualityStatus: routequalitystatus,
        routeName: routeinfo_routename,
        vps: routeinfo_validityperiodstart
      });

      return (
        <p key={route.id} className="subList">
          <label title={routename}>
            <input
              type="checkbox"
              className="routeRtzDownload"
              data-title={routename}
              data-rtz10={extension_routenumber}
            />
            {routename}
          </label>
          <button
            className="btn btn-link"
            onClick={() => navigate('/view/route/' + route.id)}
            title={'Show route details for route ' + routename}
          >
            Go to route
          </button>
        </p>
      );
    },
    [navigate]
  );

  return (
    <div>
      {loading && <Loading />}
      <h4>Routes</h4>
      {loading ? (
        <p>Loading...</p>
      ) : items && items.length ? (
        <>
          <Collapsible
            key="in"
            collapsed={routes.in.length === 0}
            header={`In (${routes.in.length})`}
          >
            {routes.in.map(r => renderRoute(r))}
          </Collapsible>
          <Collapsible
            key="out"
            collapsed={routes.out.length === 0}
            header={`Out (${routes.out.length})`}
          >
            {routes.out.map(r => renderRoute(r))}
          </Collapsible>
          <Collapsible
            key="between"
            collapsed={routes.between.length === 0}
            header={`Between (${routes.between.length})`}
          >
            {routes.between.map(r => renderRoute(r))}
          </Collapsible>
          <Collapsible
            key="opensea"
            collapsed={routes.opensea.length === 0}
            header={`Open sea and Inshore (${routes.opensea.length})`}
          >
            {routes.opensea.map(r => renderRoute(r))}
          </Collapsible>
        </>
      ) : (
        'No routes'
      )}
      {items && items.length > 0 && (
        <div className="locations-after--download-btn-container">
          <p>Download RTZ for selected routes:</p>
          <p>
            <button className="btn btn-info" onClick={() => onDownloadRoutesRtz('rtz10')}>
              Download RTZ 1.0
            </button>
          </p>
        </div>
      )}
    </div>
  );
};

export default LocationsAfter;
