import React, { useContext, useMemo } from 'react';
import { DataContext, DataContextProvider } from '../contexts/DataContext';
import { UserContextProvider } from '../contexts/UserContext';
import Disclaimer from './Disclaimer';
import Router from '../routes';
import Map from '../components/map/Map';
import Layers from '../components/map/Layers';
import { olUtils } from '@avinet/react-openlayers';
import LoadingDots from '../components/loading/LoadingDots';

import kvlogo from '../static/Logo_Kystverket-english-secondary-RGB.png';

import './App.scss';

const SplashScreen = () => {
  const { items, loading, route: routes } = useContext(DataContext);

  const initalExtent = useMemo(() => {
    if (!loading && routes) {
      return olUtils.getExtentFromFeatures(routes.map(l => l.olFeature));
    }
  }, [routes, loading]);

  return (
    <>
      {loading && (
        <div className="splash--screen">
          <img src={kvlogo} alt="Logo" className="logo" />
          <LoadingDots />
        </div>
      )}
      {items && !loading && (
        <Map initalExtent={initalExtent}>
          <Router>
            <Layers />
          </Router>
          <Disclaimer />
        </Map>
      )}
    </>
  );
};

const App = () => {
  return (
    <UserContextProvider>
      <DataContextProvider>
        <SplashScreen />
      </DataContextProvider>
    </UserContextProvider>
  );
};

export default App;
