import { SourceIndexes } from './SourceConfig';

const hostname = window.location.hostname.replace('www.', '');
const isPreview =
  process.env.NODE_ENV === 'production' && hostname === 'kystverket-routeinfo.vercel.app';
const isProd =
  process.env.NODE_ENV === 'production' &&
  (hostname === 'routeinfo.no' || hostname === 'routeinfo.kystverket.no');
let isDevSite =
  process.env.NODE_ENV !== 'production' ||
  (hostname !== 'routeinfo.no' &&
    hostname !== 'routeinfo.kystverket.no' &&
    hostname !== 'kystverket-routeinfo.vercel.app');

// Default development endpoints, use development server.
let adaptiveUrl = 'https://a3routeinfo-dev.avinet.no/';
let a3WmsUrl = 'https://a3routeinfo-dev-ogc.avinet.no/wms.ashx';
let layerIds = {
  39: SourceIndexes.CPR, //Theme: 19 - Local restrictions when sailing with PEC (Pilotage Exemption Certificate)
  28: SourceIndexes.PORT, //Theme: 14 - Ports
  27: SourceIndexes.PORT_FACILITY, //Theme: 13 - Port facilities
  29: SourceIndexes.QUAY, //Theme: 15 - Quays
  36: SourceIndexes.ROUTE, //Theme: 17 - Reference routes for navigation
  25: SourceIndexes.MTR, //Theme: 11 - Navigation rules for specific waters (in VTS service areas)
  137: SourceIndexes.SVALBARD, //Theme: 26 - Regulations and Services - Svalbard (active)
  cpr: 'layer_19', //Theme: 19 - Local restrictions when sailing with PEC (Pilotage Exemption Certificate)
  port: 'layer_14', //Theme: 14 - Ports
  port_facilities: 'layer_13', //Theme: 13 - Port facilities
  quay: 'layer_15', //Theme: 15 - Quays
  routes: 'layer_17', //Theme: 17 - Reference routes for navigation
  mtr: 'layer_11', //Theme: 11 - Navigation rules for specific waters (in VTS service areas)
  pilot: 'layer_12', //Theme: 12 - Pilot boarding (theme ut som WMS i Frontend)
  svalbard: 'layer_26' //Theme: 26 - Regulations and Services - Svalbard (active)
};

if (process.env.REACT_APP_LOCAL_SERVER) {
  // Local development endpoints
  //Not in use
}

if (isPreview || isProd) {
  // Production endpoints
  adaptiveUrl = 'https://a3cloud.kystverket.no/';
  a3WmsUrl = 'https://a3cloudmap.kystverket.no/wms.ashx';
  layerIds = {
    77: SourceIndexes.CPR, //Theme: 36 - Local restrictions when sailing with PEC (Pilotage Exemption Certificate)
    47: SourceIndexes.PORT, //Theme: 24 - Ports
    48: SourceIndexes.PORT_FACILITY, //Theme: 25 - Port facilities
    49: SourceIndexes.QUAY, //Theme: 26 - Quays
    51: SourceIndexes.ROUTE, //Theme: 28 - Reference routes for navigation
    52: SourceIndexes.MTR, //Theme: 29 - Navigation rules for specific waters (in VTS service areas)
    118: SourceIndexes.SVALBARD, //Theme: 49 - Regulations and Services - Svalbard (active)
    cpr: 'layer_36', //Theme: 36 - Local restrictions when sailing with PEC (Pilotage Exemption Certificate)
    port: 'layer_24', //Theme: 24 - Ports
    port_facilities: 'layer_25', //Theme: 25 - Port facilities
    quay: 'layer_26', //Theme: 26 - Quays
    routes: 'layer_28', //Theme: 28 - Reference routes for navigation
    mtr: 'layer_29', //Theme: 29 - Navigation rules for specific waters (in VTS service areas)
    pilot: 'layer_27', //Theme: 27 - Pilot boarding (theme ut som WMS i Frontend)
    svalbard: 'layer_49' //Theme: 49 - Regulations and Services - Svalbard (active)
  };
  isDevSite = false;
}

export default window.KystverketConfig = {
  isDevSite,
  adaptiveUrl,
  a3WmsUrl,
  terms_consent: 'route_info-terms',
  privacy_consent: 'route_info-privacy',
  accept_email_consent: 'route_info-email',
  basename: '/',
  cmsSite: 0,
  srid: 3857,
  extent: [967135.3121703134, 8139424.406603873, 1685643.3780509701, 8950879.898879305],
  mapProjCode: '3857',
  dataProjCode: '32633',
  appName: 'Kystverket: Ruter',
  layerIds,
  themes: {
    port: {
      //Ports
      id: 'locationid',
      ssn: 'ssn_ports',
      theme_uuid: '6e34448a-606d-478c-b962-42379dbd6148',
      columns: ['locationid', 'councilname', 'locationcode', 'locationnamenor', 'locationnameeng'],
      url: '/view/port/'
    },
    portfacility: {
      //Port facilities
      id: 'locationid',
      ssn: 'ssn_port_facilities',
      theme_uuid: '3b08550c-dec3-40cf-9f98-e9ec0d400b20',
      columns: [
        'locationid',
        'councilname',
        'portfacilityno',
        'locationcode',
        'locationnamenor',
        'locationnameeng'
      ],
      url: '/view/portfacility/'
    },
    quay: {
      //Quays
      id: 'locationid',
      ssn: 'ssn_quays',
      theme_uuid: '7bfa118f-4d06-460c-88a9-ad6b24f3ff59',
      columns: ['locationid', 'councilname', 'locationcode', 'locationnamenor', 'locationnameeng'],
      url: '/view/quay/'
    },
    route: {
      //Reference routes for navigation
      id: 'id',
      theme_uuid: '4e7e923f-3735-4faf-a63d-6495ab3a78fc',
      columns: [
        'auto_length',
        'ssn_port_facilities',
        'ssn_ports',
        'ssn_quays',
        'id',
        'routename', //missing
        'routeinfo_routename',
        'extension_routenumber',
        'routequalitystatus',
        'routeinfo_vesselvoyage',
        'routeinfo_validityperiodstart'
      ],
      url: '/view/route/'
    },
    mtr: {
      //Navigation rules for specific waters (in VTS service areas)
      id: 'id',
      theme_uuid: 'd5de6bf5-bbc7-41b4-858b-effe06ff6112',
      columns: ['id', 'section', 'region', 'link', 'factsheet'],
      url: '/view/mtr/'
    },
    cpr: {
      //Local restrictions when sailing with PEC (Pilotage Exemption Certificate)
      id: 'id',
      theme_uuid: 'e1ec4fa1-ca1c-44cd-ae51-6c8025d9d6d6',
      columns: [
        'id',
        'name',
        'maritimedivisionname',
        'c23_day_maxlength',
        'c23_night_maxlength',
        'c23_day_maxdraught',
        'c23_night_maxdraught',
        'c1_day_maxlength',
        'c1_night_maxlength',
        'c1_day_maxdraught',
        'c1_night_maxdraught',
        'remarkenglish',
        'sok_kl1',
        'c1_dp'
      ],
      url: '/view/cpr/'
    },
    vts: {
      //VTS service areas
      id: 'id',
      theme_uuid: 'bb63fa52-487f-4b22-8061-cf4c02ea4b3e',
      columns: ['id', 'areaname_eng', 'factsheet']
    },
    pilotBoarding: {
      //Pilot boarding (theme ut som WMS i Frontend)
      id: 'id',
      theme_uuid: '82bc4664-3ff7-4aac-bc74-29d8e2c93349',
      columns: []
    },
    svalbard: {
      //Svalbard, Regulations and Information Services
      id: 'id',
      theme_uuid:
        isProd || isPreview
          ? '0fb2beae-823b-47a7-8939-042fe5de422a'
          : 'eda583a1-4414-4fe4-b44d-98b525270179',
      columns: ['id', 'name', 'factsheet', 'link', 'description'],
      url: '/view/svalbard/'
    }
  }
};
