import { useMemo, useContext } from 'react';
import { SourceIndexes } from '../config/SourceConfig';
import { DataContext } from '../contexts/DataContext';

import config from '../config/kystverket';

const useRoutes = ({ type, locationid }) => {
  const { [SourceIndexes.ROUTE]: items, loading } = useContext(DataContext);

  const ssnType = useMemo(() => config.themes[type].ssn, [type]);

  const filteredItems = useMemo(() => {
    if (!items) return;

    return items.filter(i => i[ssnType].includes(locationid));
  }, [items, ssnType, locationid]);

  return { loading, items: filteredItems };
};

export default useRoutes;
